import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@emotion/react';

/**
 * Available themes.
 */
const themeMapping = {
  default: require('themes/default').theme,
  blueDark: require('themes/blueDark').theme,
  blueLight: require('themes/blueLight').theme,
  greyLighter: require('themes/greyLighter').theme,
};

export default function SwitchTheme({ name, children }) {
  const themeExists = name in themeMapping;

  return (
    <ThemeProvider
      theme={themeExists ? themeMapping[name] : themeMapping.default}
    >
      {children}
    </ThemeProvider>
  );
}

SwitchTheme.propTypes = {
  name: PropTypes.string,
};

SwitchTheme.defaultProps = {
  name: 'default',
};
