import React from 'react';
import { SvgStyled } from 'components/common/Icon/Icon.styles';

export default () => (
  <SvgStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 354 120">
    <g>
      <path
        d="M158.6,28h9.6c4,0,7,1,9,3c1.9,2,2.9,5,2.9,9s-1,7-2.9,9s-4.9,3-9,3h-9.6V28z M167.5,47.5
		c2.2,0,3.8-0.6,4.9-1.8c1.1-1.2,1.7-3.1,1.7-5.7c0-2.6-0.6-4.5-1.7-5.7c-1.1-1.2-2.7-1.8-4.9-1.8h-3.1v15H167.5z"
      />
      <path d="M184,27.2h5.5v4.1H184V27.2z M184.1,34h5.4v18h-5.4V34z" />
      <path
        d="M210.8,44.3h-12c0.5,2.5,2.1,3.8,5.1,3.8c0.9,0,1.8-0.1,2.6-0.4c0.9-0.3,1.8-0.6,2.7-1v4.6
		c-2,0.8-4.2,1.2-6.6,1.2c-1.8,0-3.5-0.4-4.9-1.1s-2.5-1.8-3.2-3.3c-0.8-1.4-1.2-3.1-1.2-5c0-3.2,0.8-5.6,2.4-7.4s3.8-2.6,6.7-2.6
		C208,33.2,210.8,36.9,210.8,44.3z M198.7,40.9h6.7c-0.2-1.3-0.5-2.2-1-2.8s-1.3-0.8-2.3-0.8C200.2,37.3,199.1,38.5,198.7,40.9z"
      />
      <path
        d="M217.3,51.2c-1-1-1.6-2.4-1.6-4.2v-9.1H213v-4.1h2.7v-5h5.6v5h4v4.1h-4v8.4c0,0.6,0.1,1.1,0.4,1.4
		s0.7,0.5,1.3,0.5c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.3,1.1-0.6V52c-0.9,0.5-2,0.8-3.3,0.8C219.9,52.7,218.3,52.2,217.3,51.2z"
      />
      <path
        d="M245.9,44.3h-12c0.5,2.5,2.1,3.8,5.1,3.8c0.9,0,1.8-0.1,2.6-0.4c0.9-0.3,1.8-0.6,2.7-1v4.6
		c-2,0.8-4.2,1.2-6.6,1.2c-1.8,0-3.5-0.4-4.9-1.1s-2.5-1.8-3.2-3.3c-0.8-1.4-1.2-3.1-1.2-5c0-3.2,0.8-5.6,2.4-7.4s3.8-2.6,6.7-2.6
		C243.1,33.2,245.9,36.9,245.9,44.3z M233.8,40.9h6.7c-0.2-1.3-0.5-2.2-1-2.8s-1.3-0.8-2.3-0.8C235.3,37.3,234.2,38.5,233.8,40.9z"
      />
      <path
        d="M249.6,35.7c0.4-0.3,1-0.7,1.9-1s1.9-0.7,3-1s2.2-0.4,3.2-0.4c2,0,3.4,0.3,4.1,0.9v3.9
		c-1-0.2-2.3-0.3-4.1-0.3c-1.1,0-2.1,0.1-2.8,0.2v14h-5.4L249.6,35.7L249.6,35.7z"
      />
      <path d="M294,33.8L286.4,52H281l-7.6-18.2h5.8l4.8,12.3l4.3-12.3H294z" />
      <path
        d="M297.4,50.1c-1.5-1.6-2.3-4.1-2.3-7.3c0-3.4,0.8-5.8,2.3-7.3s3.8-2.3,6.9-2.3s5.4,0.8,7,2.4s2.3,4,2.3,7.3
		c0,3.2-0.8,5.6-2.4,7.2c-1.6,1.7-3.9,2.5-6.9,2.5C301.3,52.5,299,51.7,297.4,50.1z M307.3,46.9c0.7-0.9,1-2.3,1-4
		c0-1.9-0.3-3.3-1-4.2s-1.7-1.2-3-1.2c-1.4,0-2.4,0.4-3,1.2s-0.9,2.2-0.9,4.2c0,1.8,0.3,3.2,1,4.1c0.6,0.9,1.6,1.3,3,1.3
		C305.7,48.3,306.7,47.8,307.3,46.9z"
      />
      <path
        d="M321.7,33.9c1.9-0.4,3.6-0.6,5.1-0.6c2.6,0,4.5,0.5,5.8,1.5s1.9,2.7,1.9,5V52h-5.4V40.8
		c0-2.1-1.2-3.1-3.5-3.1c-0.5,0-1.1,0.1-1.6,0.2c-0.6,0.1-1,0.3-1.3,0.4V52h-5.4V35.3C318.3,34.8,319.8,34.4,321.7,33.9z"
      />
    </g>
    <g>
      <path d="M158.6,71h5.8v9.7h8.8V71h5.8v24h-5.8v-9.5h-8.8V95h-5.8V71z" />
      <path
        d="M185.2,93.1c-1.5-1.6-2.3-4.1-2.3-7.3c0-3.4,0.8-5.8,2.3-7.3s3.8-2.3,6.9-2.3s5.4,0.8,7,2.4s2.3,4,2.3,7.3
		c0,3.2-0.8,5.6-2.4,7.2c-1.6,1.7-3.9,2.5-6.9,2.5C189.1,95.5,186.8,94.7,185.2,93.1z M195.1,89.9c0.7-0.9,1-2.3,1-4
		c0-1.9-0.3-3.3-1-4.2s-1.7-1.2-3-1.2c-1.4,0-2.4,0.4-3,1.2s-0.9,2.2-0.9,4.2c0,1.8,0.3,3.2,1,4.1c0.6,0.9,1.6,1.3,3,1.3
		C193.5,91.3,194.5,90.8,195.1,89.9z"
      />
      <path d="M205.2,95V69.9h5.3V95H205.2z" />
      <path
        d="M218,94.2c-1-1-1.6-2.4-1.6-4.2v-9.1h-2.7v-4.1h2.7v-5h5.6v5h4v4.1h-4v8.4c0,0.6,0.1,1.1,0.4,1.4
		s0.7,0.5,1.3,0.5c0.4,0,0.8-0.1,1.2-0.2s0.8-0.3,1.1-0.6V95c-0.9,0.5-2,0.8-3.3,0.8C220.6,95.7,219.1,95.2,218,94.2z"
      />
      <path d="M243.8,81l-8,9.8h8V95h-14.5v-4.2l8.8-9.9h-8.6v-4h14.3V81z" />
      <path
        d="M263.6,78.7c1.4,1.6,2.1,4,2.1,7.1c0,6.5-3.2,9.7-9.6,9.7c-5.6,0-8.4-1.6-8.4-4.8V70.1h5.3v7.4
		c0.6-0.4,1.4-0.7,2.2-0.9c0.8-0.2,1.6-0.3,2.2-0.3C260.2,76.3,262.2,77.1,263.6,78.7z M260.4,86.1c0-3.7-1.4-5.6-4.2-5.6
		c-0.5,0-1,0.1-1.6,0.2s-1.1,0.3-1.5,0.6v8.1c0,1.1,1,1.7,3,1.7C259,91.1,260.4,89.4,260.4,86.1z"
      />
      <path
        d="M269.5,78.7c0.4-0.3,1-0.7,1.9-1c0.9-0.4,1.9-0.7,3-1s2.2-0.4,3.2-0.4c2,0,3.4,0.3,4.1,0.9v3.9
		c-1-0.2-2.3-0.3-4.1-0.3c-1.1,0-2.1,0.1-2.8,0.2v14h-5.4L269.5,78.7L269.5,78.7z"
      />
      <path d="M284.9,70.2h5.5v4.1h-5.5V70.2z M285,77h5.4v18H285V77z" />
      <path
        d="M299.5,76.9c1.9-0.4,3.6-0.6,5.1-0.6c2.6,0,4.5,0.5,5.8,1.5s1.9,2.7,1.9,5V95H307V83.8
		c0-2.1-1.2-3.1-3.5-3.1c-0.5,0-1.1,0.1-1.6,0.2c-0.6,0.1-1,0.3-1.3,0.4V95h-5.4V78.3C296.1,77.8,297.5,77.4,299.5,76.9z"
      />
      <path
        d="M320.8,94.4c-1.4-0.7-2.6-1.7-3.4-3.1c-0.9-1.4-1.3-3.1-1.3-5.1c0-3.2,0.9-5.7,2.6-7.4
		c1.7-1.7,4-2.5,6.9-2.5c1,0,2,0.1,3.1,0.3c1,0.2,1.9,0.5,2.7,0.8V82c-1-0.4-1.9-0.8-2.6-1c-0.8-0.2-1.5-0.3-2.3-0.3
		c-1.6,0-2.8,0.4-3.7,1.2c-0.9,0.8-1.3,2-1.3,3.7c0,1.8,0.4,3.2,1.2,4.1c0.8,0.9,2.2,1.3,4,1.3c1.4,0,3-0.5,4.7-1.4v4.6
		c-0.9,0.5-1.8,0.8-2.7,1s-2,0.3-3.1,0.3C323.9,95.5,322.2,95.1,320.8,94.4z"
      />
      <path d="M346.2,95l-5.7-7.5V95h-5.3V69.9h5.4v13.6l5.5-6.7h6.5l-7.5,7.9l8,10.3C353.1,95,346.2,95,346.2,95z" />
    </g>
    <path
      fill="#B91448"
      d="M60.2,59.6l30,30l-30,30C60.2,119.6,60.2,59.6,60.2,59.6z"
    />
    <path fill="#DE253A" d="M60.2,119.6l-30-30l30-30V119.6z" />
    <path
      fill="#F05754"
      d="M60.4,59.8l-30,30l-30-30C0.4,59.8,60.4,59.8,60.4,59.8z"
    />
    <path fill="#FF8A6E" d="M0.4,59.8l30-30l30,30H0.4z" />
    <path fill="#E73D47" d="M60.2,59.8l-30-30l30-30V59.8z" />
    <path fill="#DC2539" d="M60-0.2l30,30l-30,30V-0.2z" />
    <path fill="#CB1A41" d="M60,59.8l30-30l30,30H60z" />
    <path
      fill="#A6104D"
      d="M120,59.8l-30,30l-30-30C60,59.8,120,59.8,120,59.8z"
    />
  </SvgStyled>
);
