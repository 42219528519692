import React from 'react';

// import { useExternalScriptEffect } from 'utils/hooks/useScriptExternal';

import Parser from 'components/common/Parser';

import {
  MailchimpSignup,
  SubmitButton,
  Input,
  Checkbox,
  Label,
} from './MailchimpSignup.styled';

export default ({
  className,
  mailchimpSignupConditionHeadline,
  mailchimpSignupConditionText,
  mailchimpSignupFormAction,
  mailchimpSignupEmailPlaceholder,
  mailchimpSignupNamePlaceholder,
  mailchimpSignupSubmitText,
  mailchimpSignupContactHeadline,
  mailchimpSignupContactText,
}) => {
  return (
    <MailchimpSignup
      action={mailchimpSignupFormAction}
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      className={`${className} validate`}
      target="_blank"
    >
      {mailchimpSignupNamePlaceholder && (
        <div>
          <Input
            type="text"
            placeholder={mailchimpSignupNamePlaceholder}
            name="NAME"
            id="mce-NAME"
          />
        </div>
      )}

      <div>
        <Input
          type="email"
          name="EMAIL"
          className="required email"
          id="mce-EMAIL"
          placeholder={mailchimpSignupEmailPlaceholder}
          required
        />
      </div>

      {mailchimpSignupContactText && (
        <>
          {mailchimpSignupContactHeadline && (
            <h5 style={{ marginBottom: 0 }}>
              {mailchimpSignupContactHeadline}
            </h5>
          )}
          {mailchimpSignupContactText && (
            <p style={{ fontSize: 12, marginTop: 0 }}>
              <Parser html={mailchimpSignupContactText} />
            </p>
          )}
          <Label>
            <Checkbox
              type="checkbox"
              id="gdpr_31129"
              name="gdpr[31129]"
              value="Y"
              className="av-checkbox gdpr"
            />
            E-Mail
          </Label>
        </>
      )}

      {mailchimpSignupConditionText && (
        <>
          {mailchimpSignupConditionHeadline && (
            <h5 style={{ marginBottom: 0 }}>
              {mailchimpSignupConditionHeadline}
            </h5>
          )}
          <Label>
            <Checkbox type="checkbox" required />
            <Parser html={mailchimpSignupConditionText} />
          </Label>
        </>
      )}

      <div>
        <SubmitButton type="submit" name="subscribe" id="mc-embedded-subscribe">
          {mailchimpSignupSubmitText}
        </SubmitButton>
      </div>

      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input
          type="text"
          name="b_f9a2d555e6f0aa8e15d98450f_85a1b2c154"
          tabIndex="-1"
          defaultValue=""
        />
      </div>
    </MailchimpSignup>
  );
};
