import React from 'react';
import { SvgStyled } from 'components/common/Icon/Icon.styles';

export default () => (
  <SvgStyled xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M5,3c0,1.1-0.7,2-2,2C1.8,5,1,4.1,1,3.1C1,2,1.8,1,3,1S5,1.9,5,3z M1,19h4V6H1V19z M14.6,6.2c-2.1,0-3.3,1.2-3.8,2h-0.1
	l-0.2-1.7H6.9C6.9,7.6,7,8.9,7,10.4V19h4v-7.1c0-0.4,0-0.7,0.1-1c0.3-0.7,0.8-1.6,1.9-1.6c1.4,0,2,1.2,2,2.8V19h4v-7.4
	C19,7.9,17.1,6.2,14.6,6.2z"
    />
  </SvgStyled>
);
