import scrollToElement from 'scroll-to-element';

export const onClientEntry = () => {
  // If the font does not load, have a fallback to show `broken` state (without right font) of site after 8 secs ~DH
  setTimeout(() => {
    document.querySelector('body').classList.add('loaded');
  }, 1000);
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash) {
    scrollToElement(location.hash, { duration: 400 });
  } else {
    window.scrollTo({ top: 0 });
  }

  return false;
};
