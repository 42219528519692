import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import MenuMain from 'components/common/MenuMain';

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMainQuery {
        craft {
          globals {
            globalMenuMain {
              ... on Craft_GlobalMenuMain {
                menuItems {
                  id
                  menuItemLink {
                    entry {
                      id
                      fullUri
                    }
                    url
                    target
                    text
                    customText
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <MenuMain
        items={data?.craft?.globals?.globalMenuMain?.menuItems || undefined}
        {...props}
      />
    )}
  />
);
