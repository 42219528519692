import styled from '@emotion/styled';

import media from 'utils/mediaqueries';

import Icon from 'components/common/Icon';
import Link from 'components/common/Link';

export const SocialsStyled = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
});

const facebook = props =>
  props.type === 'Facebook' && {
    color: props.theme.socialsFacebookColor,
    background: props.theme.socialsFacebookBackground,
    '&:hover': {
      color: props.theme.socialsFacebookColorHover,
      background: props.theme.socialsFacebookBackgroundHover,
    },
  };

const twitter = props =>
  props.type === 'Twitter' && {
    color: props.theme.socialsTwitterColor,
    background: props.theme.socialsTwitterBackground,
    '&:hover': {
      color: props.theme.socialsTwitterColorHover,
      background: props.theme.socialsTwitterBackgroundHover,
    },
  };

const xing = props =>
  props.type === 'Xing' && {
    color: props.theme.socialsXingColor,
    background: props.theme.socialsXingBackground,
    '&:hover': {
      color: props.theme.socialsXingColorHover,
      background: props.theme.socialsXingBackgroundHover,
    },
  };

const linkedin = props =>
  props.type === 'LinkedIn' && {
    color: props.theme.socialsLinkedinColor,
    background: props.theme.socialsLinkedinBackground,
    '&:hover': {
      color: props.theme.socialsLinkedinColorHover,
      background: props.theme.socialsLinkedinBackgroundHover,
    },
  };

const mail = props =>
  props.type === 'Mail' && {
    color: props.theme.socialsMailColor,
    background: props.theme.socialsMailBackground,
    '&:hover': {
      color: props.theme.socialsMailColorHover,
      background: props.theme.socialsMailBackgroundHover,
    },
  };

export const SocialsItemStyled = styled(Link)(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s linear',
    flexShrink: 0,
    marginRight: 12,
    marginBottom: 12,
    width: 42,
    height: 42,

    [media('lg')]: {
      width: 64,
      height: 64,
    },

    '&:last-of-type': {
      marginRight: 0,
    },
  },
  props =>
    props.size === 'compact' && {
      [media('lg')]: {
        width: 60,
        height: 60,
        marginRight: 25,
        marginBottom: 25,
      },
    },
  props =>
    props.size === 'small' && {
      [media('lg')]: {
        width: 48,
        height: 48,
        fontSize: '0.9em',
      },
    },
  props => ({
    ...facebook(props),
    ...twitter(props),
    ...mail(props),
    ...linkedin(props),
    ...xing(props),
    boxShadow: props.theme.socialsBoxShadow && '0 3px 6px rgba(0,0,0,.16)',
  }),
);

export const SocialsItemIconStyled = styled(Icon)({});
