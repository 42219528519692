import React from 'react';
import PropTypes from 'prop-types';

import {
  parseAsContent,
  ALLOWED_TAGS_BLOCK,
  ALLOWED_TAGS_INLINE,
} from './parse';

/**
 * Component:
 * Parser.
 *
 * @version 1.0.0
 *
 * @param {string} html
 * @param {string} allowedTags
 *
 * @returns {*}
 */
export default function Parser({ html, allowedTags }) {
  const parsedChildren = parseAsContent(html, { allowedTags });

  return <>{parsedChildren}</>;
}

Parser.propTypes = {
  html: PropTypes.string.isRequired,
  allowedTags: PropTypes.oneOf([ALLOWED_TAGS_BLOCK, ALLOWED_TAGS_INLINE]),
};

Parser.defaultProps = {
  allowedTags: ALLOWED_TAGS_INLINE,
};
