import styled from '@emotion/styled';

import media from 'utils/mediaqueries';

export const BackgroundFixedStyled = styled('div')(
  {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  props =>
    props.image && {
      backgroundImage: `url(${props.image})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom -12vh right 20%',
    },
  // We need to position the background images nicely one by one.
  props =>
    props.name === 'squaresBig' && {
      backgroundPosition: 'bottom 2vh right 1vh',
      backgroundSize: 'auto 40vh',
      opacity: 0.7,

      [media('lg')]: {
        backgroundPosition: 'top -14vh right -6vh',
        backgroundSize: 'auto 90vh',
      },
    },
  props =>
    props.name === 'squaresSmallFading' && {
      backgroundSize: '50vw auto',
      opacity: 0.2,

      [media('lg')]: {
        backgroundPosition: 'top 35vh right -5vh',
        backgroundSize: '25vw auto',
      },
    },
  props =>
    props.name === 'squaresSmallBordered' && {
      backgroundPosition: 'top 40px right -70px',
      backgroundSize: '360px 80%',
      opacity: 0,

      [media('xl')]: {
        opacity: 1,
      },
    },
);
