import React from 'react';
import PropTypes from 'prop-types';

import { useLockBodyScroll } from 'utils/hooks/useLockBodyScroll';

import CraftMenuMain from 'components/container/CraftMenuMain';

import { MenuOverlayStyled } from './MenuOverlay.styles';

export default function MenuOverlay({ menuOpen, onLinkClick }) {
  // Call hook to lock body scroll
  useLockBodyScroll(menuOpen);

  return (
    <MenuOverlayStyled menuOpen={menuOpen}>
      <CraftMenuMain onLinkClick={onLinkClick} />
    </MenuOverlayStyled>
  );
}

MenuOverlay.propTypes = {
  menuOpen: PropTypes.bool,
};

MenuOverlay.defaultProps = {
  menuOpen: false,
};
