export const colors = {
  black: '#000000',
  white: '#ffffff',
  brown: '#2C2629',
  grey: 'rgba(0, 0, 0, .1)',
  greyDark: '#4C545C',
  greyMedium: '#5D6F7C',
  greyLight: '#F2F2F2',
  greyLighter: '#F7F7F7',
  greyThirty: '#B2B2B2',
  greyTwenty: '#eae9e9',
  blueLight: '#F0F5F8',
  blueDark: '#111437', // primary
  blueDarker: '#0E2A37',
  blue: '#0093FF',
  yellow: '#FDBE34',
  violet: '#AE3E95',
  magenta: '#AD0056',
  red: '#C6003D',
  redBright: '#C5003D',
  redLight: '#F05754',
  purple: '#6C009D',
  // Social media
  facebook: '#3B5998',
  twitter: '#1DA1F2',
  xing: '#126567',
  linkedin: '#0274B4',
  mail: '#4C545C',
};

export const shadows = {
  base: '0 3px 20px rgba(0,0,0,.16)',
  small: '0 3px 6px rgba(0,0,0,.16)',
  wide: '3px 3px 12px rgba(0,0,0,.26)',
};

export const gradients = {
  blueToBlueDark: ['#076DB7', '#04375C'],
  blue: ['#29506F', '#0D3552'],
  // remove the following commented out line if customer doesnt want it back until 12/19 ~RS
  // redToPurple: [colors.red, colors.redLight],
  redToPurple: [colors.red, colors.purple],
  transparentToBlueLighter: ['transparent', 'rgba(240,245,248,0.35)'], // #F0F5F8
  blueToPurple: ['#076DB7', '#04375C'],
};

export const fonts = {
  base: 'Montserrat, sans-serif',
  headlines: 'Barlow, sans-serif',
  sizes: {
    base: 17,
    mobile: 15,
    small: '.9em',
    smaller: '.8em',
    large: '1.1em',
    h1: '2em',
    h2: '2em',
    h3: '1.5em',
    h4: '1.2em',
    h5: '1em',
    h6: '1em',
    h1Mobile: '1.5em',
    h2Mobile: '1.5em',
    h3Mobile: '1.3em',
  },
};

export const sizes = {
  spacing: 12,
  spacingLarge: 60,
};

export const grid = {
  gutter: 60,
  width: {
    sm: 540,
    md: 720,
    lg: 960,
    xl: 1200,
  },
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1280,
};
