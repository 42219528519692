import styled from '@emotion/styled';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

// General styles
const sharedLinkStyles = {
  color: 'inherit',

  '&:focus, &:active, &:hover': {
    color: 'inherit',
  },
};

// Internal link in new tab
export const LinkStyled = styled('a')(sharedLinkStyles);

// Internal link
export const GatsbyLinkStyled = styled(GatsbyLink)(sharedLinkStyles);

// External link
export const OutboundLinkStyled = styled(OutboundLink)(sharedLinkStyles);
