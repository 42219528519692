import { colors } from 'config/variables';

export const theme = {
  name: 'blueLight',

  /**
   * Common
   */
  color: colors.blueDark,
  backgroundColor: colors.blueLight,

  /**
   * Component: Socials
   */
  socialsBoxShadow: false,
  socialsFacebookColor: colors.white,
  socialsFacebookColorHover: colors.black,
  socialsFacebookBackground: colors.facebook,
  socialsFacebookBackgroundHover: colors.red,
  socialsMailColor: colors.white,
  socialsMailColorHover: colors.black,
  socialsMailBackground: colors.mail,
  socialsMailBackgroundHover: colors.red,
  socialsXingColor: colors.white,
  socialsXingColorHover: colors.black,
  socialsXingBackground: colors.xing,
  socialsXingBackgroundHover: colors.red,
  socialsTwitterColor: colors.white,
  socialsTwitterColorHover: colors.black,
  socialsTwitterBackground: colors.twitter,
  socialsTwitterBackgroundHover: colors.red,
  socialsLinkedinColor: colors.white,
  socialsLinkedinColorHover: colors.black,
  socialsLinkedinBackground: colors.linkedin,
  socialsLinkedinBackgroundHover: colors.red,

  /**
   * Component: Button
   */
  buttonColor: colors.blueDark,
  buttonBackgroundColor: 'transparent',
  buttonBorderColor: colors.greyTwenty,

  buttonIconColor: colors.redLight,
  buttonIconBackgroundColor: colors.blueDark,
  buttonIconBorderColor: colors.blueDark,

  // State: hover/focus/active
  buttonColorHover: colors.white,
  buttonBackgroundColorHover: colors.blueDark,
  buttonBorderColorHover: colors.blueDark,

  buttonIconColorHover: colors.redLight,
  buttonIconBackgroundColorHover: colors.blueDark,
  buttonIconBorderColorHover: colors.redLight,

  /**
   * Component: PageIndicator
   */
  pageIndicatorItemBackground: colors.black,
};
