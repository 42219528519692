import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { IconStyled } from './Icon.styles';
import * as icons from './icons';

/**
 * Available icons.
 *
 * To add a new icon, simply create a component in icons/ and
 * import it in icons/index.js
 */
const availableIcons = Object.keys(icons).reduce(
  (iconElements, iconKey) => ({
    ...iconElements,
    [iconKey]: createElement(icons[iconKey]),
  }),
  {},
);

export default function Icon({ name, className, onClick, disabled }) {
  return availableIcons[name] ? (
    <IconStyled className={className} onClick={onClick} disabled={disabled}>
      {availableIcons[name]}
    </IconStyled>
  ) : null;
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(availableIcons)).isRequired,
  disabled: PropTypes.bool,
};

Icon.defaultProps = {
  disabled: false,
};
