import React from 'react';
import { useScrollPosition } from 'utils/hooks/useScrollPosition';

import NavbarStickyMemo from './NavbarSticky';

export default function Navbar({
  menuOpen,
  onBurgerClick,
  onLinkClick,
  ...rest
}) {
  const sticky = useScrollPosition() > 50;

  return (
    <NavbarStickyMemo
      sticky={sticky}
      menuOpen={menuOpen}
      onLinkClick={onLinkClick}
      onBurgerClick={onBurgerClick}
      {...rest}
    />
  );
}
