import { colors, gradients } from 'config/variables';

import { gradientLinear } from 'utils/styles';

export const theme = {
  name: 'default',

  /**
   * Common
   */
  color: colors.blueDark,
  backgroundColor: 'transparent',

  /**
   * Component: Link
   */
  textLinkColor: colors.magenta,
  textLinkColorHover: colors.magenta,

  /**
   * Component: Button
   */
  buttonColor: colors.blueDark,
  buttonBackgroundColor: 'transparent',
  buttonBorderColor: colors.grey,

  buttonIconColor: colors.redLight,
  buttonIconBackgroundColor: colors.blueDark,
  buttonIconBorderColor: colors.blueDark,

  // State: hover/focus/active
  buttonColorHover: colors.white,
  buttonBackgroundColorHover: colors.blueDark,
  buttonBorderColorHover: colors.blueDark,

  buttonIconColorHover: colors.redLight,
  buttonIconBackgroundColorHover: colors.blueDark,
  buttonIconBorderColorHover: colors.redLight,

  /**
   * Component: Socials
   */
  socialsBoxShadow: true,
  socialsFacebookcolor: colors.blueDark,
  socialsFacebookColorHover: colors.white,
  socialsFacebookBackground: colors.white,
  socialsFacebookBackgroundHover: colors.red,
  socialsMailcolor: colors.blueDark,
  socialsMailColorHover: colors.white,
  socialsMailBackground: colors.white,
  socialsMailBackgroundHover: colors.red,
  socialsXingcolor: colors.blueDark,
  socialsXingColorHover: colors.white,
  socialsXingBackground: colors.white,
  socialsXingBackgroundHover: colors.red,
  socialsTwittercolor: colors.blueDark,
  socialsTwitterColorHover: colors.white,
  socialsTwitterBackground: colors.white,
  socialsTwitterBackgroundHover: colors.red,
  socialsLinkedincolor: colors.blueDark,
  socialsLinkedinColorHover: colors.white,
  socialsLinkedinBackground: colors.white,
  socialsLinkedinBackgroundHover: colors.red,

  /**
   * Component: PageIndicator
   */
  pageIndicatorItemBackground: colors.black,

  /**
   * Component: Footer
   */
  footerAreaLargeBackground: '#29506F',
  footerAreaMediumBackground: colors.white,
  footerAreaSmallBackground: colors.greyLight,
  footerContentLargeBackground: `${gradientLinear('90deg', gradients.blue)}`,
  footerContentMediumBackground: colors.white,
  footerContentSmallBackground: colors.greyLight,

  /**
   * Component: Newsletter Color
   */
  newsletterButtonColor: colors.white,
  newsletterButtonBackground: colors.red,
};
