import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import MenuMeta from 'components/common/MenuMeta';

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMetaQuery {
        craft {
          globals {
            globalMenuMeta {
              ... on Craft_GlobalMenuMeta {
                menuItems {
                  id
                  menuItemLink {
                    entry {
                      id
                      fullUri
                    }
                    url
                    target
                    text
                    customText
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <MenuMeta
        items={data?.craft?.globals?.globalMenuMeta?.menuItems || undefined}
        {...props}
      />
    )}
  />
);
