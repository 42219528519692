import { css, keyframes } from '@emotion/react';
import media from 'utils/mediaqueries';

import { colors, fonts } from 'config/variables';
import { toPx } from 'utils/styles';

import FontMontserrat300Eot from 'assets/fonts/montserrat-v14-latin-300.eot';
import FontMontserrat300Woff2 from 'assets/fonts/montserrat-v14-latin-300.woff2';
import FontMontserrat300Woff from 'assets/fonts/montserrat-v14-latin-300.woff';
import FontMontserrat300Ttf from 'assets/fonts/montserrat-v14-latin-300.ttf';
import FontMontserrat300Svg from 'assets/fonts/montserrat-v14-latin-300.svg';

import FontMontserrat400Eot from 'assets/fonts/montserrat-v14-latin-regular.eot';
import FontMontserrat400Woff2 from 'assets/fonts/montserrat-v14-latin-regular.woff2';
import FontMontserrat400Woff from 'assets/fonts/montserrat-v14-latin-regular.woff';
import FontMontserrat400Ttf from 'assets/fonts/montserrat-v14-latin-regular.ttf';
import FontMontserrat400Svg from 'assets/fonts/montserrat-v14-latin-regular.svg';

import FontMontserrat600Eot from 'assets/fonts/montserrat-v14-latin-600.eot';
import FontMontserrat600Woff2 from 'assets/fonts/montserrat-v14-latin-600.woff2';
import FontMontserrat600Woff from 'assets/fonts/montserrat-v14-latin-600.woff';
import FontMontserrat600Ttf from 'assets/fonts/montserrat-v14-latin-600.ttf';
import FontMontserrat600Svg from 'assets/fonts/montserrat-v14-latin-600.svg';

import FontBarlow300Eot from 'assets/fonts/barlow-v4-latin-300.eot';
import FontBarlow300Woff2 from 'assets/fonts/barlow-v4-latin-300.woff2';
import FontBarlow300Woff from 'assets/fonts/barlow-v4-latin-300.woff';
import FontBarlow300Ttf from 'assets/fonts/barlow-v4-latin-300.ttf';
import FontBarlow300Svg from 'assets/fonts/barlow-v4-latin-300.svg';

import FontBarlow400Eot from 'assets/fonts/barlow-v4-latin-regular.eot';
import FontBarlow400Woff2 from 'assets/fonts/barlow-v4-latin-regular.woff2';
import FontBarlow400Woff from 'assets/fonts/barlow-v4-latin-regular.woff';
import FontBarlow400Ttf from 'assets/fonts/barlow-v4-latin-regular.ttf';
import FontBarlow400Svg from 'assets/fonts/barlow-v4-latin-regular.svg';

import FontBarlow600Eot from 'assets/fonts/barlow-v4-latin-600.eot';
import FontBarlow600Woff2 from 'assets/fonts/barlow-v4-latin-600.woff2';
import FontBarlow600Woff from 'assets/fonts/barlow-v4-latin-600.woff';
import FontBarlow600Ttf from 'assets/fonts/barlow-v4-latin-600.ttf';
import FontBarlow600Svg from 'assets/fonts/barlow-v4-latin-600.svg';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

export const globalStyles = css`
  html {
    box-sizing: border-box;
  }

  body {
    background-color: ${colors.white};
    font-family: ${fonts.base};
    font-size: ${toPx(fonts.sizes.mobile)};
    line-height: 1.7;
    font-weight: 300;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    opacity: 0;
    ${[media('md')]} {
      font-size: ${toPx(fonts.sizes.base)};
    }
  }

  .loaded {
    animation: ${fadeIn} 1s forwards;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  strong {
    font-weight: 500;
  }

  /* montserrat-300 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url(${FontMontserrat300Eot});
    src: local('Montserrat Light'), local('Montserrat-Light'),
      url(${FontMontserrat300Eot}#iefix) format('embedded-opentype'),
      url(${FontMontserrat300Woff2}) format('woff2'),
      url(${FontMontserrat300Woff}) format('woff'),
      url(${FontMontserrat300Ttf}) format('truetype'),
      url(${FontMontserrat300Svg}#Montserrat) format('svg');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url(${FontMontserrat400Eot});
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
      url(${FontMontserrat400Eot}#iefix) format('embedded-opentype'),
      url(${FontMontserrat400Woff2}) format('woff2'),
      url(${FontMontserrat400Woff}) format('woff'),
      url(${FontMontserrat400Ttf}) format('truetype'),
      url(${FontMontserrat400Svg}#Montserrat) format('svg');
  }

  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url(${FontMontserrat600Eot});
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
      url(${FontMontserrat600Eot}#iefix) format('embedded-opentype'),
      url(${FontMontserrat600Woff2}) format('woff2'),
      url(${FontMontserrat600Woff}) format('woff'),
      url(${FontMontserrat600Ttf}) format('truetype'),
      url(${FontMontserrat600Svg}#Montserrat) format('svg');
  }

  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    src: url(${FontBarlow300Eot});
    src: local('Barlow Light'), local('Barlow-Light'),
      url(${FontBarlow300Eot}#iefix) format('embedded-opentype'),
      url(${FontBarlow300Woff2}) format('woff2'),
      url(${FontBarlow300Woff}) format('woff'),
      url(${FontBarlow300Ttf}) format('truetype'),
      url(${FontBarlow300Svg}#Barlow) format('svg');
  }

  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: url(${FontBarlow400Eot});
    src: local('Barlow Regular'), local('Barlow-Regular'),
      url(${FontBarlow400Eot}#iefix) format('embedded-opentype'),
      url(${FontBarlow400Woff2}) format('woff2'),
      url(${FontBarlow400Woff}) format('woff'),
      url(${FontBarlow400Ttf}) format('truetype'),
      url(${FontBarlow400Svg}#Barlow) format('svg');
  }

  @font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    src: url(${FontBarlow600Eot});
    src: local('Barlow SemiBold'), local('Barlow-SemiBold'),
      url(${FontBarlow600Eot}#iefix) format('embedded-opentype'),
      url(${FontBarlow600Woff2}) format('woff2'),
      url(${FontBarlow600Woff}) format('woff'),
      url(${FontBarlow600Ttf}) format('truetype'),
      url(${FontBarlow600Svg}#Barlow) format('svg');
  }
`;
