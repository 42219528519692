import React from 'react';
import PropTypes from 'prop-types';
import {
  BurgerStyled,
  BurgerInnerStyled,
  BurgerBarStyled,
} from './Burger.styles';

/**
 * Component: Burger
 *
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function Burger({ className, active, sticky, onClick }) {
  return (
    <BurgerStyled
      className={className}
      onClick={onClick}
      active={active}
      sticky={sticky}
    >
      <BurgerInnerStyled>
        <BurgerBarStyled active={active} />
        <BurgerBarStyled active={active} />
        <BurgerBarStyled active={active} />
      </BurgerInnerStyled>
    </BurgerStyled>
  );
}

Burger.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

Burger.defaultProps = {
  onClick: () => console.log('Your 🍔 seems to be missing a click handler.'),
  active: false,
};
