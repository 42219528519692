import styled from '@emotion/styled';

import { toPx } from 'utils/styles';
import { colors, sizes } from 'config/variables';
import media from 'utils/mediaqueries';

import Link from 'components/common/Link';

export const MenuMainStyled = styled('nav')({
  position: 'relative',
});

export const MenuItemStyled = styled(Link)({
  display: 'block',
  color: colors.white,
  textDecoration: 'none',
  fontSize: '1.5em',
  fontWeight: 600,
  textAlign: 'center',
  transition: 'color 0.2s linear',
  lineHeight: 1.2,
  padding: `${toPx(sizes.spacing)} 0`,

  [media('md')]: {
    fontSize: '2em',
    padding: `${toPx(sizes.spacing * 1.5)} 0`,
  },

  '&:last-child': {
    marginTop: '1.8em',
  },

  span: {
    position: 'relative',
  },

  'span::after': {
    position: 'absolute',
    display: 'block',
    content: '""',
    opacity: 0,
    height: 2,
    width: '100%',
    left: '50%',
    transform: 'translate(-50%, 5px)',
    transition: 'opacity .2s, transform .2s ease-in-out',
    backgroundColor: '#e95756',
  },

  '&:hover': {
    color: colors.white,

    'span::after': {
      opacity: 1,
      transform: 'translate(-50%, 0)',
    },
  },
});
