/**
 * Parser.
 *
 * Can parse whatever you feed him into React components.
 * Also strips unwanted stuff out to keep things nice and tidy.
 *
 * @version 0.0.1
 * @author Jan Willem Henckel <jan.henckel@dunckelfeld.de>
 */
import React from 'react';
import striptags from 'striptags';
import HtmlToReact from 'html-to-react';

// Components
import Text from 'components/common/Text';
import TextLink from 'components/common/TextLink';
import Headline from 'components/common/Headline';
import Blockquote from 'components/common/Blockquote';

/**
 * Tags.
 *
 * Defines the set of tags that are
 * allowed to be in the html.
 */
const tags = {
  inline: ['a', 'span', 'strong', 'b', 'em', 'i', 'u', 'del', 'br', 'img'],
  block: [
    'div',
    'p',
    'ul',
    'ol',
    'li',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'iframe',
    'blockquote',
    'figure',
    'table',
    'thead',
    'tbody',
    'tfoot',
    'tr',
    'td',
    'th',
    'caption',
  ],
};

export const ALLOWED_TAGS_BLOCK = tags.inline.concat(tags.block);
export const ALLOWED_TAGS_INLINE = tags.inline;

/**
 * Element:
 * Wrapper.
 *
 * @param node
 * @param children
 * @param index
 * @returns {object}
 */
function processWrapperNode(node, children, index) {
  return <div key={index}>{children}</div>;
}

/**
 * Element:
 * Text.
 *
 * @param node
 * @param children
 * @param index
 * @returns {object}
 */
function processParagraphNode(node, children, index) {
  return <Text key={index}>{children}</Text>;
}

/**
 * Element:
 * Blockquote.
 *
 * @param node
 * @param children
 * @param index
 * @returns {object}
 */
function processBlockquoteNode(node, children, index) {
  const cite = node.attribs.cite || undefined;

  return (
    <Blockquote key={index} cite={cite}>
      {children}
    </Blockquote>
  );
}

/**
 * Element:
 * Headline.
 *
 * @param node
 * @param children
 * @param index
 * @returns {string}
 */
function processHeadlineNode(node, children, index) {
  const id = node.attribs.id || undefined;
  const name = node.attribs.name || undefined;
  const element = node.name || undefined;

  return (
    <Headline key={index} id={id} name={name} element={element}>
      {children}
    </Headline>
  );
}

/**
 * Element:
 * Link.
 *
 * @param node
 * @param children
 * @param index
 * @returns {*}
 */
function processLinkNode(node, children, index) {
  const href = node.attribs.href || null;
  const target = node.attribs.target || null;
  const rel = node.attribs.rel || null;
  const onClick = node.attribs.onClick || null;

  return (
    <TextLink key={index} to={href} target={target} rel={rel} onClick={onClick}>
      {children}
    </TextLink>
  );
}

/**
 * Helper function:
 * Is node valid?
 *
 * Checks if node is one of our
 * accepted tags or of type text.
 *
 * @param node
 * @returns {number|boolean}
 */
function isValidNode(node) {
  return (
    tags.inline.includes(node.name) ||
    tags.block.includes(node.name) ||
    node.type === 'text'
  );
}

/**
 * Function:
 * Parse HTML.
 *
 * @param html
 * @returns {*}
 */
function parseHtml(html) {
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      shouldProcessNode: node => node.name === 'div',
      processNode: processWrapperNode,
    },
    {
      shouldProcessNode: node => node.name === 'p',
      processNode: processParagraphNode,
    },
    {
      shouldProcessNode: node => node.name === 'blockquote',
      processNode: processBlockquoteNode,
    },
    {
      shouldProcessNode: node => node.name === 'a',
      processNode: processLinkNode,
    },
    {
      shouldProcessNode: node => node.name && node.name.match(/h[1-6]/),
      processNode: processHeadlineNode,
    },
    {
      shouldProcessNode: isValidNode,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  // Parse HTML with instructions.
  const htmlToReactParser = new HtmlToReact.Parser(React);
  return htmlToReactParser.parseWithInstructions(
    html,
    () => true,
    processingInstructions,
  );
}

/**
 * Helper function:
 * Remove scripts.
 *
 * @param text
 * @returns {void|*|string|never}
 */
function removeScripts(text) {
  return text.replace(/<script([\S\s]*?)<\/script>/gi, '');
}

/**
 * Function:
 * New line to <br>.
 *
 * @param {string} str
 */
export function nl2br(str) {
  if (typeof str !== 'string') {
    return str;
  }

  const NEWLINE_REGEX = /(\r\n|\r|\n)/g;

  return str.split(NEWLINE_REGEX).map((line, index) => {
    if (line.match(NEWLINE_REGEX)) {
      // eslint-disable-next-line react/no-array-index-key
      return <br key={index} />;
    }
    return line;
  });
}

/**
 * Function:
 * Parse as content
 *
 * Allows inline as well as block nodes.
 *
 * @param {*} html
 * @param {array} allowedTags
 */
export function parseAsContent(
  html,
  { allowedTags = ALLOWED_TAGS_BLOCK } = {},
) {
  if (!html) return null;

  let htmlMutate = html;

  // Sanitize the whole of the html.
  // Strip breaks and remove scripts.
  htmlMutate = String(htmlMutate).replace(/(\r\n|\r|\n)/g, '');
  htmlMutate = removeScripts(htmlMutate);
  htmlMutate = striptags(htmlMutate, allowedTags);

  return parseHtml(htmlMutate);
}
