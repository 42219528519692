import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import CookieInfo from 'components/common/CookieInfo';

export default props => (
  <StaticQuery
    query={graphql`
      query CookieInfoQuery {
        craft {
          globals {
            globalPrivacy {
              ... on Craft_GlobalPrivacy {
                globalCookieInfo {
                  globalCookieInfoTitle
                  globalCookieInfoText {
                    content
                  }
                  globalCookieInfoLink {
                    entry {
                      id
                      fullUri
                    }
                    url
                    target
                    text
                    customText
                  }
                  globalCookieInfoButtonLabel
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        globalCookieInfoTitle,
        globalCookieInfoText,
        globalCookieInfoLink,
        globalCookieInfoButtonLabel,
      } = data?.craft?.globals?.globalPrivacy?.globalCookieInfo?.[0];

      return (
        (globalCookieInfoTitle || globalCookieInfoText) && (
          <CookieInfo
            title={globalCookieInfoTitle || undefined}
            text={globalCookieInfoText?.content || undefined}
            linkUrl={
              globalCookieInfoLink?.entry?.fullUri ||
              globalCookieInfoLink?.url ||
              undefined
            }
            linkLabel={
              globalCookieInfoLink?.customText ||
              globalCookieInfoLink?.text ||
              undefined
            }
            buttonLabel={globalCookieInfoButtonLabel || undefined}
            {...props}
          />
        )
      );
    }}
  />
);
