import { colors } from 'config/variables';

export const theme = {
  name: 'blueDark',

  /**
   * Common
   */
  color: colors.white,
  background: colors.blueDark,
  backgroundColor: colors.blueDark,

  /**
   * Component: Link
   */
  textLinkColor: colors.red,
  textLinkColorHover: colors.red,

  /**
   * Component: Button
   */
  buttonColor: colors.white,
  buttonBackgroundColor: 'transparent',
  buttonBorderColor: colors.white,

  buttonIconColor: colors.blueDark,
  buttonIconBackgroundColor: colors.white,
  buttonIconBorderColor: colors.white,

  /**
   * Component: Socials
   */
  socialsBoxShadow: false,
  socialsFacebookColor: colors.white,
  socialsFacebookColorHover: colors.black,
  socialsFacebookBackground: colors.facebook,
  socialsFacebookBackgroundHover: colors.red,
  socialsMailColor: colors.white,
  socialsMailColorHover: colors.black,
  socialsMailBackground: colors.mail,
  socialsMailBackgroundHover: colors.red,
  socialsXingColor: colors.white,
  socialsXingColorHover: colors.black,
  socialsXingBackground: colors.xing,
  socialsXingBackgroundHover: colors.red,
  socialsTwitterColor: colors.white,
  socialsTwitterColorHover: colors.black,
  socialsTwitterBackground: colors.twitter,
  socialsTwitterBackgroundHover: colors.red,
  socialsLinkedinColor: colors.white,
  socialsLinkedinColorHover: colors.black,
  socialsLinkedinBackground: colors.linkedin,
  socialsLinkedinBackgroundHover: colors.red,

  /**
   * Component: PageIndicator
   */
  pageIndicatorItemBackground: colors.white,
};
