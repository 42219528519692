import React from 'react';
import PropTypes from 'prop-types';

import { TextStyled } from './Text.styles';

/**
 * Component: Text
 *
 * @version 1.0.0
 *
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function Text({ className, children }) {
  return <TextStyled className={className}>{children}</TextStyled>;
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
};

Text.defaultProps = {};
