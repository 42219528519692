import React from 'react';
import PropTypes from 'prop-types';
import { LogoStyled } from './Logo.styles';

/**
 * Component: Logo
 *
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function Logo({ className, small }) {
  return <LogoStyled name="Logo4" className={className} small={small} />;
}

Logo.propTypes = {
  small: PropTypes.bool,
};

Logo.defaultProps = {
  small: false,
};
