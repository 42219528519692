import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';
import { fonts, sizes, colors } from 'config/variables';

const aLtrAfter = keyframes`
  0% {transform: translateX(-100%)}
  100% {transform: translateX(101%)}
`;

const aLtrBefore = keyframes`
  0% {transform: translateX(0)}
  100% {transform: translateX(200%)}
`;

// General styles
const sharedHeadlineStyles = props => ({
  fontWeight: 400,
  fontFamily: fonts.headlines,
  width: '100%', // Because the parent is probably flexed.
  lineHeight: 1.5,
  letterSpacing: -0.5,
  strong: {
    fontWeight: 600,
  },

  u: {
    textDecoration: 'none',
    position: 'relative',
    overflow: 'hidden',
    display: props.isVisible ? 'inline-flex' : 'none',
    fontWeight: 600,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      background: colors.red,
      animation: `${aLtrBefore} 2s cubic-bezier(.77,0,.18,1) forwards`,
      transform: 'translateX(0)',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      background: props.theme.backgroundColor || 'white',
      animation: `${aLtrAfter} 2s cubic-bezier(.77,0,.18,1) forwards`,
      transform: 'translateX(-101%)',
    },
  },
});

// Appearance: colored
const sharedHeadlineColoredStyles = {
  fontWeight: 400,

  strong: {
    fontWeight: 600,
    color: colors.red,
  },

  'strong:nth-of-type(2n)': {
    color: colors.purple,
    // remove the following commented out line if customer doesnt want it back until 12/19 ~RS
    // color: colors.redLight,
  },
};

// Appearance: thinned
const sharedHeadlineThinnedStyles = {
  fontWeight: 300,

  strong: {
    fontWeight: 600,
  },
};

// Align: left/right
const sharedHeadlineAlignLeftStyles = {
  textAlign: 'left',
};

const sharedHeadlineAlignRightStyles = {
  textAlign: 'right',
};

// Element: h1
export const HeadlineH1Styled = styled('h1')(
  props => sharedHeadlineStyles(props),
  {
    fontSize: fonts.sizes.h1Mobile,
    margin: `${toPx(sizes.spacing)} 0 ${toPx(sizes.spacing * 2)}`,

    [media('md')]: {
      fontSize: fonts.sizes.h1,
      margin: `0 0 ${toPx(sizes.spacing * 3)}`,
    },
  },
  props => props.appearance === 'colored' && sharedHeadlineColoredStyles,
  props => props.appearance === 'thinned' && sharedHeadlineThinnedStyles,
  props => props.align === 'left' && sharedHeadlineAlignLeftStyles,
  props => props.align === 'right' && sharedHeadlineAlignRightStyles,
);

// Element: h2
export const HeadlineH2Styled = styled('h2')(
  props => sharedHeadlineStyles(props),
  {
    fontSize: fonts.sizes.h2Mobile,
    margin: `${toPx(sizes.spacing)} 0 ${toPx(sizes.spacing * 2)}`,

    [media('md')]: {
      fontSize: fonts.sizes.h2,
      margin: `0 0 ${toPx(sizes.spacing * 4)}`,
    },
  },
  props => props.appearance === 'colored' && sharedHeadlineColoredStyles,
  props => props.appearance === 'thinned' && sharedHeadlineThinnedStyles,
  props => props.align === 'left' && sharedHeadlineAlignLeftStyles,
  props => props.align === 'right' && sharedHeadlineAlignRightStyles,
);

// Element: h3
export const HeadlineH3Styled = styled('h3')(
  props => sharedHeadlineStyles(props),
  {
    fontSize: fonts.sizes.h3Mobile,
    margin: `0 0 ${sizes.spacing}px`,

    [media('md')]: {
      fontSize: fonts.sizes.h3,
    },
  },
  props => props.appearance === 'colored' && sharedHeadlineColoredStyles,
  props => props.appearance === 'thinned' && sharedHeadlineThinnedStyles,
  props => props.align === 'left' && sharedHeadlineAlignLeftStyles,
  props => props.align === 'right' && sharedHeadlineAlignRightStyles,
);

// Element: h4
export const HeadlineH4Styled = styled('h4')(
  props => sharedHeadlineStyles(props),
  {
    fontSize: fonts.sizes.h4,
    margin: `0 0 ${sizes.spacing}px`,
  },
  props => props.appearance === 'colored' && sharedHeadlineColoredStyles,
  props => props.appearance === 'thinned' && sharedHeadlineThinnedStyles,
  props => props.align === 'left' && sharedHeadlineAlignLeftStyles,
  props => props.align === 'right' && sharedHeadlineAlignRightStyles,
);

// Element: h5
export const HeadlineH5Styled = styled('h5')(
  props => sharedHeadlineStyles(props),
  {
    fontSize: fonts.sizes.h5,
    margin: `0 0 ${sizes.spacing}px`,
  },
  props => props.appearance === 'colored' && sharedHeadlineColoredStyles,
  props => props.appearance === 'thinned' && sharedHeadlineThinnedStyles,
  props => props.align === 'left' && sharedHeadlineAlignLeftStyles,
  props => props.align === 'right' && sharedHeadlineAlignRightStyles,
);

// Element: h6
export const HeadlineH6Styled = styled('h6')(
  props => sharedHeadlineStyles(props),
  {
    fontSize: fonts.sizes.h6,
    margin: `0 0 ${sizes.spacing}px`,
  },
  props => props.appearance === 'colored' && sharedHeadlineColoredStyles,
  props => props.appearance === 'thinned' && sharedHeadlineThinnedStyles,
  props => props.align === 'left' && sharedHeadlineAlignLeftStyles,
  props => props.align === 'right' && sharedHeadlineAlignRightStyles,
);
