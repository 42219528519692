import styled from '@emotion/styled';
import media from 'utils/mediaqueries';

import Wrapper from 'components/common/Wrapper';

export const PageIndicatorStyled = styled('div')({
  pointerEvents: 'none',
  position: 'fixed',
  top: '15vh',
  left: 0,
  width: '100%',
  height: '80vh',
  display: 'none',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  [media('lg')]: {
    display: 'flex',
  },
});

export const PageIndicatorWrapperStyled = styled(Wrapper)({
  pointerEvents: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '30vh',
});

export const PageIndicatorItemStyled = styled('div')(
  props => ({
    pointerEvents: 'auto',
    display: 'block',
    cursor: 'pointer',
    position: 'relative',
    height: 2,
    width: 14,
    flexShrink: 0,
    padding: 10,
    overflow: 'hidden',

    '&:after': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate(-9px, -50%)',
      height: 2,
      width: 14,
      pointerEvents: 'auto',
      opacity: 0.3,
      transition: 'transform 0.3s linear, opacity 0.3s linear',
      background: props.theme.pageIndicatorItemBackground,
    },
  }),
  props =>
    props.isActive && {
      '&:after': {
        transform: 'translate(0, -50%)',
        opacity: 1,
      },
    },
);
