import React from 'react';
import { SvgStyled } from 'components/common/Icon/Icon.styles';

export default () => (
  <SvgStyled
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448.03 177.62"
    fill="currentColor"
  >
    <path d="M6,.46H22.86c12.83,0,21.55,8.09,21.55,20s-8.72,20-21.55,20H6ZM22.52,35.4c9.86,0,16.19-6,16.19-15s-6.33-15-16.19-15H11.74v30Z" />
    <path d="M62.53.46h5.7v39.9h-5.7Z" />
    <path d="M118.56,35.4v5h-29V.46h28.17v5H95.3V17.67h20v4.85h-20V35.4Z" />
    <path d="M145.12,5.42H131.44v-5h33v5H150.77V40.36h-5.65Z" />
    <path d="M209,35.4v5h-29V.46h28.16v5H185.76V17.67h20v4.85h-20V35.4Z" />
    <path d="M254.34,40.36,245.73,28.1c-.8.06-1.66.12-2.51.12h-9.86V40.36h-5.7V.46h15.56c10.37,0,16.65,5.24,16.65,13.91,0,6.15-3.2,10.6-8.78,12.59l9.46,13.4Zm-.17-26c0-5.7-3.82-8.95-11.12-8.95h-9.69v18h9.69C250.35,23.37,254.17,20.07,254.17,14.37Z" />
    <path d="M337.9.46l-17.5,39.9h-5.65L297.2.46h6.15l14.37,32.78L332.2.46Z" />
    <path d="M348.72,20.41C348.72,8.72,357.73,0,370,0s21.15,8.67,21.15,20.41-9,20.41-21.15,20.41S348.72,32.09,348.72,20.41Zm36.72,0A15,15,0,0,0,370,5.07c-8.95,0-15.57,6.5-15.57,15.34S361,35.74,370,35.74,385.44,29.25,385.44,20.41Z" />
    <path d="M443.58.46v39.9H438.9L415,10.6V40.36h-5.7V.46h4.67l23.95,29.75V.46Z" />
    <path d="M41.39,68.86v39.9H32.15V92.4H14v16.36H4.79V68.86H14V84.59H32.15V68.86Z" />
    <path d="M49.19,88.81C49.19,77,58.43,68.17,71,68.17s21.84,8.72,21.84,20.64S83.57,109.45,71,109.45,49.19,100.67,49.19,88.81Zm34.32,0C83.51,81.23,78.15,76,71,76S58.54,81.23,58.54,88.81,63.9,101.58,71,101.58,83.51,96.39,83.51,88.81Z" />
    <path d="M100.66,68.86h9.24v32.38h20v7.52H100.66Z" />
    <path d="M140.51,76.38H127.74V68.86h34.77v7.52H149.74v32.38h-9.23Z" />
    <path d="M200.64,101.24v7.52H165.75v-6l21.9-26.4H166.21V68.86h33.58v6L178,101.24Z" />
    <path d="M244,97.87c0,6.9-5.47,10.89-16,10.89H207.42V68.86h19.5c10,0,15.11,4.16,15.11,10.37A9.6,9.6,0,0,1,236.72,88,9.86,9.86,0,0,1,244,97.87ZM216.6,75.81v9.41h9.18c4.5,0,6.95-1.6,6.95-4.73s-2.45-4.68-6.95-4.68ZM234.73,96.9c0-3.36-2.57-5-7.36-5H216.6v9.87h10.77C232.16,101.81,234.73,100.32,234.73,96.9Z" />
    <path d="M277.31,108.76l-7.7-11.12h-8.49v11.12h-9.24V68.86h17.28c10.66,0,17.33,5.53,17.33,14.48a13,13,0,0,1-8.21,12.59l9,12.83Zm-8.67-32.38h-7.52V90.29h7.52c5.65,0,8.5-2.62,8.5-7S274.29,76.38,268.64,76.38Z" />
    <path d="M294.63,68.86h9.24v39.9h-9.24Z" />
    <path d="M350.78,68.86v39.9H343.2L323.3,84.53v24.23h-9.12V68.86h7.64l19.84,24.22V68.86Z" />
    <path d="M358.58,88.81c0-12,9.24-20.64,21.67-20.64,6.89,0,12.65,2.51,16.41,7.07l-5.92,5.47a12.88,12.88,0,0,0-10-4.67c-7.47,0-12.77,5.24-12.77,12.77s5.3,12.77,12.77,12.77a12.75,12.75,0,0,0,10-4.73l5.92,5.47c-3.76,4.62-9.52,7.13-16.47,7.13C367.82,109.45,358.58,100.84,358.58,88.81Z" />
    <path d="M418.26,93.08l-5.35,5.59v10.09h-9.18V68.86h9.18V87.5l17.67-18.64h10.26L424.31,86.64l17.5,22.12H431Z" />
    <path
      fill="currentColor"
      d="M40.7,137.26l-17.5,39.9H17.56L0,137.26H6.16L20.52,170,35,137.26Z"
    />
    <path
      fill="currentColor"
      d="M82.25,172.2v5h-29v-39.9H81.46v5H59v12.25H79v4.85H59V172.2Z"
    />
    <path
      fill="currentColor"
      d="M133.33,137.26v39.9h-4.68L104.71,147.4v29.76H99v-39.9h4.67L127.63,167V137.26Z"
    />
    <path
      fill="currentColor"
      d="M160.74,142.22H147.06v-5h33v5H166.38v34.94h-5.64Z"
    />
    <path
      fill="currentColor"
      d="M192.72,160V137.26h5.7v22.46c0,8.83,4,12.82,11.23,12.82s11.29-4,11.29-12.82V137.26h5.52V160c0,11.57-6.32,17.67-16.87,17.67S192.72,171.52,192.72,160Z"
    />
    <path
      fill="currentColor"
      d="M272.35,177.16l-8.61-12.26c-.8.06-1.65.12-2.51.12h-9.86v12.14h-5.7v-39.9h15.56c10.38,0,16.65,5.24,16.65,13.91,0,6.15-3.19,10.6-8.78,12.59l9.46,13.4Zm-.17-26c0-5.71-3.82-8.95-11.12-8.95h-9.69v17.95h9.69C268.36,160.17,272.18,156.87,272.18,151.17Z"
    />
    <path
      fill="currentColor"
      d="M323.59,172.2v5h-29v-39.9h28.16v5H300.33v12.25h20v4.85h-20V172.2Z"
    />
    <path
      fill="currentColor"
      d="M336.7,172.6l2.11-4.45c3,2.68,8,4.62,13.16,4.62,6.9,0,9.87-2.68,9.87-6.16,0-9.74-24.17-3.59-24.17-18.41,0-6.16,4.78-11.4,15.27-11.4a23.48,23.48,0,0,1,12.83,3.53l-1.88,4.57a20.56,20.56,0,0,0-10.95-3.25c-6.78,0-9.69,2.85-9.69,6.32,0,9.75,24.17,3.65,24.17,18.3,0,6.1-4.9,11.35-15.45,11.35C345.88,177.62,339.89,175.56,336.7,172.6Z"
    />
    <polygon
      fill="#eb5855"
      points="414.2 138.13 384.33 176.62 391.79 176.62 421.66 138.13 414.2 138.13"
    />
    <polygon
      fill="#eb5855"
      points="440.57 138.13 410.7 176.62 418.17 176.62 448.03 138.13 440.57 138.13"
    />
  </SvgStyled>
);
