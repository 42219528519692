import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';
import { colors, sizes, fonts, shadows } from 'config/variables';

import Headline from 'components/common/Headline';
import Link from 'components/common/Link';

export const CookieInfoStyled = styled('div')({
  position: 'fixed',
  padding: `${toPx(sizes.spacing * 3)}`,
  bottom: 0,
  right: 0,
  width: '100%',
  color: colors.white,
  backgroundColor: colors.brown,
  zIndex: 100,
  boxShadow: shadows.small,

  [media('sm')]: {
    maxWidth: '60%',
    margin: `${toPx(sizes.spacing * 2)}`,
  },

  [media('md')]: {
    maxWidth: 480,
  },
});

export const HeadlineStyled = styled(Headline)({
  color: colors.red,
});

export const ContentStyled = styled('div')({
  display: 'block',
  flex: 1,
  marginBottom: 20,
  fontSize: fonts.sizes.smaller,

  [media('sm')]: {
    marginBottom: 0,
    marginRight: sizes.spacingDouble,
  },

  p: {
    margin: `${sizes.spacingHalf} 0`,

    [media('md')]: {
      margin: `${sizes.spacingQuarter} 0`,
    },
  },
});

export const ActionsStyled = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  margin: `${toPx(sizes.spacing * 2)} 0 0`,

  [media('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: `${toPx(sizes.spacing * 4)} 0 0`,
  },
});

export const LinkStyled = styled(Link)({
  display: 'inline-block',
  color: colors.red,
  fontSize: fonts.sizes.smaller,
  textDecoration: 'none',
  borderBottom: `1px solid ${colors.white}`,
  margin: `${toPx(sizes.spacing)} 0 ${toPx(sizes.spacing * 2)}`,
  padding: `0 0 ${toPx(sizes.spacing / 2)}`,

  [media('sm')]: {
    margin: `${toPx(sizes.spacing)} ${toPx(sizes.spacing * 3)}`,
  },
});

export const ButtonStyled = styled('button')({
  cursor: 'pointer',
  flex: 'none',
  fontFamily: fonts.base,
  fontSize: fonts.sizes.smaller,
  backgroundColor: colors.red,
  color: colors.blueDark,
  border: 0,
  padding: `${toPx(sizes.spacing * 2)}`,

  '&:hover, &:focus, &:active': {
    outline: 0,
    backgroundColor: colors.white,
  },
});
