import styled from '@emotion/styled';

import { toPx } from 'utils/styles';
import { sizes, colors } from 'config/variables';
import media from 'utils/mediaqueries';

import Wrapper from 'components/common/Wrapper';
import Link from 'components/common/Link';

export const NavbarStyled = styled('div')(
  {
    pointerEvents: 'none', // important for hero section and its pointer events, make sure to activate it again in sub components ~DH
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: colors.blueDark,
    transition:
      'color .3s .1s, background-color .2s linear, transform .3s ease-in-out',

    [media(0, 'sm')]: {
      backgroundColor: 'rgba(255,255,255,1)',
      transform: `translate(0, ${toPx(-sizes.spacing * 2)})`,
    },
  },
  // Active navbar.
  props =>
    props.active && {
      color: colors.white,

      [media(0, 'sm')]: {
        backgroundColor: 'transparent',
      },
    },
  // Sticky navbar.
  props =>
    props.sticky &&
    !props.active && {
      backgroundColor: 'rgba(255,255,255,1)',
      transform: `translate(0, ${toPx(-sizes.spacing * 2)})`,
    },
);

export const NavbarInnerStyled = styled(Wrapper)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${toPx(sizes.spacing * 3)} 0 ${toPx(sizes.spacing)}`,
});

export const NavbarInnerLinkStyled = styled(Link)({
  display: 'flex',
});
