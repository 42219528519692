import { colors } from 'config/variables';

export const theme = {
  name: 'greyLighter',

  /**
   * Common
   */
  color: colors.blueDark,
  backgroundColor: colors.greyLighter,

  /**
   * Component: Button
   */
  buttonBackgroundColor: 'transparent',

  /**
   * Component: Socials
   */
  socialsBoxShadow: true,
  socialsFacebookcolor: colors.blueDark,
  socialsFacebookColorHover: colors.white,
  socialsFacebookBackground: colors.white,
  socialsFacebookBackgroundHover: colors.red,
  socialsMailcolor: colors.blueDark,
  socialsMailColorHover: colors.white,
  socialsMailBackground: colors.white,
  socialsMailBackgroundHover: colors.red,
  socialsXingcolor: colors.blueDark,
  socialsXingColorHover: colors.white,
  socialsXingBackground: colors.white,
  socialsXingBackgroundHover: colors.red,
  socialsTwittercolor: colors.blueDark,
  socialsTwitterColorHover: colors.white,
  socialsTwitterBackground: colors.white,
  socialsTwitterBackgroundHover: colors.red,
  socialsLinkedincolor: colors.blueDark,
  socialsLinkedinColorHover: colors.white,
  socialsLinkedinBackground: colors.white,
  socialsLinkedinBackgroundHover: colors.red,

  /**
   * Component: PageIndicator
   */
  pageIndicatorItemBackground: colors.black,
};
