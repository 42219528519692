import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Socials from 'components/common/Socials';

export default props => (
  <StaticQuery
    query={graphql`
      query SocialsQuery {
        craft {
          globals {
            globalSocial {
              ... on Craft_GlobalSocial {
                socialItems {
                  id
                  socialItemType
                  socialItemLink {
                    url
                    target
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Socials
        items={data?.craft?.globals?.globalSocial?.socialItems || undefined}
        {...props}
      />
    )}
  />
);
