import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';
import { colors, sizes } from 'config/variables';

import Link from 'components/common/Link';

export const MenuMetaStyled = styled('nav')({
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  alignSelf: 'center',

  [media('sm')]: {
    width: '60%',
  },

  [media('md')]: {
    width: '100%',
  },
});

export const MenuItemStyled = styled(Link)({
  color: colors.blueDark,
  marginRight: 10,
  textDecoration: 'none',
  textTransform: 'uppercase',
  fontSize: 9,
  fontWeight: 600,
  padding: `${toPx(sizes.spacing)} 0`,
});
