import React from 'react';
import PropTypes from 'prop-types';

import { BackgroundFixedStyled } from './BackgroundFixed.styles';

/**
 * Fixed background options.
 *
 * Doing nothing but being normal background images.
 * They are used in the styles to get the required image.
 */
const fixedBackgroundOptions = {
  squaresBig: require('assets/img/bg_squares_big.svg'),
  squaresSmallBordered: require('assets/img/bg_squares_small_bordered.svg'),
  squaresSmallFading: require('assets/img/bg_squares_small_fading.svg'),
};

/**
 * Segment component: BackgroundFixed
 *
 * @returns {null|object}
 */
export default function BackgroundFixed({ name, className }) {
  return (
    <BackgroundFixedStyled
      className={className}
      name={name}
      image={
        name in fixedBackgroundOptions ? fixedBackgroundOptions[name] : null
      }
    />
  );
}

BackgroundFixed.propTypes = {
  name: PropTypes.oneOf(Object.keys(fixedBackgroundOptions)),
};

BackgroundFixed.defaultProps = {
  name: null,
};
