import styled from '@emotion/styled';
import LinkComponent from 'components/common/Link';
import Button from 'components/common/Button';
import { colors } from 'config/variables';

export const MailchimpSignup = styled('form')({
  margin: '12px 0',
});

export const Link = styled(LinkComponent)({
  margin: '12px 0',
});

export const Label = styled('label')({
  fontSize: 14,
  display: 'block',
  marginBottom: 24,
  userSelect: 'none',
  color: colors.blueDark,
  cursor: 'pointer',
});

export const InputGroup = styled('div')({
  display: 'flex',
  maxWidth: 560,
  flexDirection: 'row',
  boxShadow: '0 3px 6px rgba(0, 0, 0, .07)',
});

export const Input = styled('input')({
  padding: 24,
  font: 'inherit',
  lineHeight: 1,
  flexGrow: 1,
  fontSize: 12,
  fontWeight: 600,
  border: `1px solid ${colors.grey}`,
  color: colors.blueDark,
  borderRadius: '50px',
  borderWidth: 1,
  boxShadow: 'none',
  marginBottom: 24,
  width: 470,
  maxWidth: '100%',
  background: 'transparent',
  transition: 'borderColor 0.2s ease-in-out',
  outline: 0,
  '&:focus': {
    borderColor: colors.blueDark,
  },
});

export const Checkbox = styled('input')({
  cursor: 'pointer',
  marginRight: 6,
});

export const SubmitButton = styled(Button)({
  width: 470,
  marginTop: '0 !important',
  maxWidth: '100%',
});
