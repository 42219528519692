import styled from '@emotion/styled';

import { gradientLinear } from 'utils/styles';
import { colors, gradients } from 'config/variables';

import backgroundSvg from 'assets/img/bg_squares_big.svg';

export const MenuOverlayStyled = styled('div')(
  {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    zIndex: 190,

    // Behaviour
    opacity: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
    transform: 'translate(0, 100%)',
    transition: 'transform .3s ease-in-out .15s, opacity .15s linear .25s',

    // Appearance
    color: colors.white,
    background: `url(${backgroundSvg}), ${gradientLinear(
      '45deg',
      gradients.redToPurple,
    )}`,
    backgroundSize: 'auto 130%, auto',
    backgroundPosition: 'top 20% center,top left',
    backgroundRepeat: 'no-repeat, no-repeat',

    // Kinda animate the direct child
    // This it not the prettiest of animations overall, make it nicer. 🤷🏻‍ ~RS
    '& > *': {
      opacity: 0,
      transform: 'translate(0, 100px)',
      transition: 'transform 0s ease-in-out .45s, opacity .15s linear',
    },
  },
  // State: menu open
  props =>
    props.menuOpen && {
      opacity: 1,
      pointerEvents: 'all',
      transform: 'translate(0, 0)',
      transition: 'transform .4s ease-in-out, opacity .2s linear .2s',

      // Kinda animate the direct child
      '& > *': {
        opacity: 1,
        transform: 'translate(0, 0)',
        transition: 'transform .3s ease-in-out .2s, opacity .4s linear .3s',
      },
    },
);
