import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/common/Link';

import {
  ButtonStyled,
  IconWrapperStyled,
  IconStyled,
  TextStyled,
} from './Button.styles';

export default function Button({
  children,
  className,
  iconName,
  to,
  type,
  onClick,
  target,
}) {
  if (type !== 'submit' && !to && !onClick) {
    console.warn('Found button without use on this page, so it is hidden.');
    return null;
  }

  return (
    <ButtonStyled
      as={to ? Link : 'button'} // Render button as Link if needed
      to={to}
      type={type === 'submit' && 'submit'}
      target={target}
      onClick={onClick}
      className={className}
      icon={iconName && iconName.length > 0}
    >
      {iconName && (
        <IconWrapperStyled>
          <IconStyled name={iconName} />
        </IconWrapperStyled>
      )}
      <TextStyled>{children}</TextStyled>
    </ButtonStyled>
  );
}

Button.propTypes = {
  iconName: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  iconName: 'ArrowRight',
  to: null,
  onClick: null,
};
