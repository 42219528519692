import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';
import { grid } from 'config/variables';

export const WrapperStyled = styled('div')(
  {
    position: 'relative',
    width: `calc(100% - ${toPx(grid.gutter)})`,
    maxWidth: toPx(grid.width.xl),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [media('sm')]: {
      width: `calc(100% - ${toPx(grid.gutter * 2)})`,
    },

    [media('lg')]: {
      width: `calc(100% - ${toPx(grid.gutter)})`,
    },

    // Trying to manage dimensions in IE11 which goes nuts with flexbox.
    '& > *': {
      maxWidth: '100%',
    },
  },
  props => ({
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
  }),
  props =>
    props.flexDirection && {
      [media('md')]: {
        flexDirection: props.flexDirection,
      },
    },
  props =>
    props.paddedLeft && {
      [media('lg')]: {
        paddingLeft: 85,
      },

      [media('xl')]: {
        paddingLeft: 170,
      },
    },
  props =>
    props.paddedRight && {
      [media('lg')]: {
        paddingRight: 85,
      },

      [media('xl')]: {
        paddingRight: 170,
      },
    },
  props =>
    props.smallPaddedLeft && {
      [media('lg')]: {
        paddingLeft: 60,
      },

      [media('xl')]: {
        paddingLeft: 60,
      },
    },
  props =>
    props.smallPaddedRight && {
      [media('lg')]: {
        paddingRight: 60,
      },

      [media('xl')]: {
        paddingRight: 60,
      },
    },
);
