import React from 'react';
import PropTypes from 'prop-types';

import { TextLinkStyled } from './TextLink.styles';

export default function TextLink({ children, ...rest }) {
  return <TextLinkStyled {...rest}>{children}</TextLinkStyled>;
}

TextLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
};

TextLink.defaultProps = {
  to: null,
  onClick: null,
};
