import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import scrollToElement from 'scroll-to-element';

import SwitchTheme from 'components/common/SwitchTheme';

import { LayoutContext } from 'components/common/Layout';
import {
  PageIndicatorWrapperStyled,
  PageIndicatorStyled,
  PageIndicatorItemStyled,
} from './PageIndicator.styles';

export default function PageIndicator({ items }) {
  const { activeSegmentId, themeName } = useContext(LayoutContext);
  return items ? (
    <SwitchTheme name={themeName}>
      <PageIndicatorStyled>
        <PageIndicatorWrapperStyled>
          {items.map(item => (
            <PageIndicatorItemStyled
              key={item?.id}
              isActive={item?.segmentElementId === activeSegmentId}
              onClick={() => {
                scrollToElement(`#${item?.segmentElementId}`);
              }}
            />
          ))}
        </PageIndicatorWrapperStyled>
      </PageIndicatorStyled>
    </SwitchTheme>
  ) : null;
}

PageIndicator.propTypes = {
  items: PropTypes.array,
};

PageIndicator.defaultProps = {
  items: null,
};
