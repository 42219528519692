import styled from '@emotion/styled';

import { colors } from 'config/variables';
import media from 'utils/mediaqueries';

export const BurgerStyled = styled('button')(
  {
    pointerEvents: 'initial', // reset important for hero section and its pointer events, make sure to activate it again in sub components ~DH
    flex: 'none',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: 42,
    height: 42,
    border: 0,
    padding: 0,
    backgroundColor: colors.white,
    transition: 'background-color 0.2s linear',

    [media('sm')]: {
      boxShadow: '0 3px 6px rgba(0,0,0,.16)',
    },

    [media('md')]: {
      width: 60,
      height: 60,
    },

    '&:hover, &:focus, &:active': {
      WebkitTapHighlightColor: 'transparent',
    },

    '@media(hover: hover)': {
      '&:hover, &:focus, &:active': {
        backgroundColor: colors.red,
        color: colors.white,
        outline: 0,
      },
    },
  },
  props =>
    props.active && {
      backgroundColor: colors.red,
    },
  props =>
    props.sticky && {
      boxShadow: 'none',
    },
);

export const BurgerInnerStyled = styled('div')({
  position: 'relative',
  width: 17,
  height: 12,

  [media('md')]: {
    width: 20,
    height: 14,
  },
});

export const BurgerBarStyled = styled('span')(
  {
    pointerEvents: 'none',
    position: 'absolute',
    display: 'block',
    content: '""',
    width: 17,
    height: 2,
    backgroundColor: 'currentColor',
    transformOrigin: '50% 50%',

    [media('md')]: {
      width: 20,
    },

    // Top bar
    '&:nth-of-type(1)': {
      top: 0,
    },

    // Middle bar
    '&:nth-of-type(2)': {
      opacity: '1',
      top: 5,

      [media('md')]: {
        top: 6,
      },
    },

    // Bottom bar
    '&:nth-of-type(3)': {
      top: 10,

      [media('md')]: {
        top: 12,
      },
    },
  },
  props =>
    props.active && {
      // Top bar
      '&:nth-of-type(1)': {
        top: '6px',
        transform: 'rotate(45deg)',
      },

      // Middle bar
      '&:nth-of-type(2)': {
        opacity: 0,
      },

      // Bottom bar
      '&:nth-of-type(3)': {
        top: '6px',
        transform: 'rotate(-45deg)',
      },
    },
);
