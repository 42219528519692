import React, { memo } from 'react';

import Logo from 'components/common/Logo';
import Burger from 'components/common/Burger';

import {
  NavbarStyled,
  NavbarInnerStyled,
  NavbarInnerLinkStyled,
} from './Navbar.styles';

function NavbarSticky({ menuOpen, sticky, onBurgerClick, onLinkClick }) {
  return (
    <NavbarStyled active={menuOpen} sticky={sticky}>
      <NavbarInnerStyled>
        <NavbarInnerLinkStyled to="/" onClick={onLinkClick}>
          <Logo small={sticky} />
        </NavbarInnerLinkStyled>
        <Burger onClick={onBurgerClick} active={menuOpen} sticky={sticky} />
      </NavbarInnerStyled>
    </NavbarStyled>
  );
}

/**
 * Wrapping the component into a memo function.
 * Only updating it if the sticky property changes
 * to avoid triggering rerender on every scroll.
 */
const NavbarStickyMemo = memo(NavbarSticky, (oldProps, newProps) => {
  if (oldProps.sticky !== newProps.sticky) return false;
  if (oldProps.menuOpen !== newProps.menuOpen) return false;

  return true;
});

export default NavbarStickyMemo;
