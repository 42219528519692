import React from 'react';
import { SvgStyled } from 'components/common/Icon/Icon.styles';

export default () => (
  <SvgStyled
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 461.02 178.6"
    preserveAspectRatio="xMinYMid meet"
  >
    <title>logo_dvh</title>
    <path d="M6.07.46H23c12.9,0,21.66,8.14,21.66,20.06S35.87,40.57,23,40.57H6.07ZM22.63,35.59c9.91,0,16.27-6.08,16.27-15.07S32.54,5.45,22.63,5.45H11.8V35.59Z" />
    <path d="M62.86.46h5.73V40.57H62.86Z" />
    <path d="M119.19,35.59v5H90.13V.46h28.31v5H95.81V17.77h20.11v4.87H95.81v13Z" />
    <path d="M145.89,5.45H132.13v-5h33.18v5H151.56V40.57h-5.67Z" />
    <path d="M210.13,35.59v5H181V.46h28.31v5H186.75V17.77h20.11v4.87H186.75v13Z" />
    <path d="M255.68,40.57,247,28.25c-.8.06-1.66.12-2.52.12H234.6v12.2h-5.73V.46h15.64c10.43,0,16.73,5.27,16.73,14,0,6.19-3.21,10.66-8.82,12.67l9.51,13.46Zm-.17-26.13c0-5.73-3.84-9-11.17-9H234.6v18h9.74c7.33.06,11.17-3.27,11.17-9Z" />
    <path d="M348.13.46l-17.6,40.11h-5.67L307.21.46h6.19l14.44,32.95L342.39.46Z" />
    <path d="M359,20.52C359,8.77,368,0,380.39,0s21.25,8.71,21.25,20.52S392.59,41,380.39,41,359,32.26,359,20.52Zm36.9,0A15.06,15.06,0,0,0,381.22,5.1h-.83c-9,0-15.65,6.54-15.65,15.42s6.65,15.41,15.65,15.41S395.91,29.4,395.91,20.52Z" />
    <path d="M454.37.46V40.57h-4.7L425.6,10.66V40.57h-5.73V.46h4.7l24.06,29.91V.46Z" />
    <path d="M41.6,69.23v40.11H32.32V92.89H14.13v16.45H4.85V69.23h9.28V85H32.36V69.23Z" />
    <path d="M51.13,89.28c0-11.92,9.28-20.74,21.94-20.74s22,8.77,22,20.74S85.69,110,73.13,110,51.13,101.2,51.13,89.28Zm34.49,0c0-7.62-5.39-12.83-12.55-12.83S60.52,81.66,60.52,89.28s5.39,12.84,12.55,12.84S85.63,96.9,85.63,89.28Z" />
    <path d="M104.57,69.23h9.28v32.54H134v7.57H104.57Z" />
    <path d="M146.31,76.79H133.47V69.23h35v7.56H155.59v32.55h-9.28Z" />
    <path d="M208.45,101.77v7.57H173.38v-6l22-26.53H173.84V69.23h33.75v6l-22,26.53Z" />
    <path d="M253.74,98.39c0,6.94-5.5,11-16,11H217V69.23h19.59c10,0,15.19,4.18,15.19,10.43a9.63,9.63,0,0,1-5.33,8.82A9.9,9.9,0,0,1,253.74,98.39ZM226.18,76.22v9.45h9.23c4.53,0,7-1.6,7-4.75s-2.46-4.7-7-4.7Zm18.22,21.2c0-3.38-2.57-5-7.39-5H226.18v9.92H237C241.83,102.35,244.4,100.86,244.4,97.42Z" />
    <path d="M288.9,109.34l-7.77-11.18h-8.54v11.18h-9.28V69.23h17.36c10.72,0,17.42,5.55,17.42,14.55a13,13,0,0,1-8.25,12.66l9,12.9Zm-8.71-32.55h-7.57v14h7.57c5.67,0,8.53-2.63,8.53-7S285.86,76.79,280.19,76.79Z" />
    <path d="M308,69.23h9.29v40.11H308Z" />
    <path d="M366.13,69.23v40.11h-7.62L338.51,85v24.36h-9.17V69.23H337L357,93.58V69.23Z" />
    <path d="M375.67,89.28c0-12.09,9.28-20.74,21.77-20.74,6.94,0,12.73,2.52,16.51,7.11l-6,5.5a12.91,12.91,0,0,0-10.09-4.7c-7.5,0-12.83,5.27-12.83,12.83s5.33,12.84,12.83,12.84A12.78,12.78,0,0,0,408,97.36l6,5.5c-3.78,4.64-9.57,7.16-16.56,7.16C385,110,375.67,101.37,375.67,89.28Z" />
    <path d="M437.35,93.58,432,99.2v10.14h-9.22V69.23H432V88l17.77-18.73H460L443.42,87.1,461,109.34H450.19Z" />
    <path d="M40.91,138,23.32,178.14H17.65L0,138H6.19L20.63,171,35.18,138Z" />
    <path d="M84.58,173.12v5H55.47V138h28.3v5H61.2v12.32H81.31v4.87H61.2v13Z" />
    <path d="M137.81,138v40.11h-4.68l-24.07-29.91v29.91h-5.73V138H108l24.1,29.87V138Z" />
    <path d="M167.27,143H153.51v-5h33.18v5H172.94v35.12h-5.67Z" />
    <path d="M201.3,160.8V138H207v22.58c0,8.88,4.06,12.89,11.28,12.89s11.35-4,11.35-12.89V138h5.56v22.81c0,11.63-6.36,17.76-17,17.76S201.3,172.43,201.3,160.8Z" />
    <path d="M283.25,178.1l-8.65-12.31c-.81.05-1.67.11-2.52.11h-9.95v12.2h-5.7V138h15.7c10.43,0,16.73,5.27,16.73,14,0,6.19-3.21,10.66-8.83,12.67l9.51,13.46ZM283.13,152c0-5.73-3.84-9-11.18-9h-9.82V161h9.74C279.24,161,283.13,157.7,283.13,152Z" />
    <path d="M336.66,173.12v5H307.55V138h28.31v5H313.28v12.32h20.11v4.87H313.28v13Z" />
    <path d="M351.73,173.52l2.12-4.47a20.71,20.71,0,0,0,13.24,4.64c6.93,0,9.91-2.69,9.91-6.19,0-9.8-24.3-3.61-24.3-18.51,0-6.18,4.82-11.46,15.36-11.46,4.7,0,9.57,1.26,12.89,3.56l-1.89,4.58a20.75,20.75,0,0,0-11-3.27c-6.82,0-9.74,2.87-9.74,6.37,0,9.79,24.3,3.66,24.3,18.39,0,6.13-4.93,11.4-15.53,11.4C361,178.56,354.94,176.5,351.73,173.52Z" />
    <rect x="394.81" y="167.77" width="66.21" height="10.79" fill="#ea5956" />
  </SvgStyled>
);
