import React from 'react';
import PropTypes from 'prop-types';

import { MenuMetaStyled, MenuItemStyled } from './MenuMeta.styles';

/**
 * Component: Logo
 *
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function MenuMain({ className, items, onLinkClick }) {
  return (
    <MenuMetaStyled className={className}>
      {items.map(item => {
        const link = item?.menuItemLink;

        return (
          <MenuItemStyled
            key={`menu-item-${item.id}`}
            to={link.entry?.fullUri || link.url}
            target={link.target}
            onClick={onLinkClick}
          >
            {link.customText || link.text}
          </MenuItemStyled>
        );
      })}
    </MenuMetaStyled>
  );
}

MenuMain.propTypes = {
  items: PropTypes.array,
};

MenuMain.defaultProps = {
  items: [],
};
