import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import { toPx } from 'utils/styles';

import { sizes, colors } from 'config/variables';

export const BlockquoteStyled = styled('blockquote')({
  position: 'relative',
  color: colors.magenta,
  lineHeight: 1.8,
  fontSize: '1.1em',
  fontWeight: 400,
  margin: `${toPx(sizes.spacing)} 0 ${toPx(sizes.spacing * 4)}`,
  padding: `0 ${toPx(sizes.spacing * 2)}`,

  [media('sm')]: {
    fontSize: '1.2em',
    margin: `${toPx(sizes.spacing)} 0 ${toPx(sizes.spacing * 4)}`,
    padding: `0 ${toPx(sizes.spacing * 4)}`,
  },

  [media('md')]: {
    margin: `${toPx(sizes.spacing * 4)} 0 ${toPx(sizes.spacing * 7)}`,
    padding: `0 ${toPx(sizes.spacing * 8)}`,
  },

  '&::after': {
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    color: colors.greyDark,
    content: 'attr(cite)',
    marginTop: `${toPx(sizes.spacing)}`,

    [media('md')]: {
      marginTop: `${toPx(sizes.spacing * 2)}`,
    },
  },
});
