import React, { useState } from 'react';
import { Global } from '@emotion/react';

import SwitchTheme from 'components/common/SwitchTheme';
import Navbar from 'components/common/Navbar';
import CraftFooter from 'components/container/CraftFooter';
import MenuOverlay from 'components/common/MenuOverlay';
import CraftCookieInfo from 'components/container/CraftCookieInfo';
import PageIndicator from 'components/common/PageIndicator';

import { LayoutContextProvider } from 'components/common/Layout';
import { globalStyles } from './Layout.styles';

export default function Layout({ children, pageContext }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const segmentsWithElementIds = children?.props?.data?.craft?.entry?.pageBuilderNeo?.filter(
    item => item?.segmentElementId !== undefined,
  );

  return (
    <SwitchTheme>
      <LayoutContextProvider>
        <Global styles={globalStyles} />
        <Navbar
          menuOpen={menuOpen}
          onLinkClick={() => setMenuOpen(false)}
          onBurgerClick={e => {
            e.target.blur();
            setMenuOpen(!menuOpen);
          }}
        />
        <>{children}</>
        {pageContext?.type === 'home' && (
          <PageIndicator items={segmentsWithElementIds} />
        )}
        <MenuOverlay
          menuOpen={menuOpen}
          onLinkClick={() => setMenuOpen(false)}
        />
        <CraftFooter />
        <CraftCookieInfo />
      </LayoutContextProvider>
    </SwitchTheme>
  );
}
