import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import Parser from 'components/common/Parser';
import {
  CookieInfoStyled,
  HeadlineStyled,
  ContentStyled,
  ActionsStyled,
  ButtonStyled,
  LinkStyled,
} from './CookieInfo.styles';

export default function CookieInfo({
  title,
  text,
  linkUrl,
  linkLabel,
  buttonLabel,
}) {
  const [acceptedCookies, setAcceptedCookies] = useState(undefined);

  // Load cookies on mount
  // Doing this at this point makes sure, that there is no component flash on page load.
  useEffect(() => {
    setAcceptedCookies(cookie.load('accepted_cookies') === 'true');
  }, []);

  // Set cookie acceptance when it changes.
  useEffect(() => {
    if (acceptedCookies === true) {
      const expires = new Date();
      const now = new Date();
      expires.setDate(now.getDate() + 14);
      cookie.save('accepted_cookies', true, { expires, path: '/' });
    }
  }, [acceptedCookies]);

  // Only show cookie info when cookies got loaded
  // and the value is actually "false".
  if (acceptedCookies === false) {
    return (
      <CookieInfoStyled>
        <HeadlineStyled element="h5">
          <Parser html={title} />
        </HeadlineStyled>
        <ContentStyled>
          <Parser html={text} />
        </ContentStyled>
        <ActionsStyled>
          <LinkStyled to={linkUrl}>{linkLabel}</LinkStyled>
          <ButtonStyled onClick={() => setAcceptedCookies(true)}>
            {buttonLabel || 'Akzeptieren'}
          </ButtonStyled>
        </ActionsStyled>
      </CookieInfoStyled>
    );
  }

  return null;
}

CookieInfo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkUrl: PropTypes.string,
  linkLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
};

CookieInfo.defaultProps = {
  title: '',
  text: '',
  linkUrl: '',
  linkLabel: '',
  buttonLabel: '',
};
