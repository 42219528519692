import React from 'react';
import PropTypes from 'prop-types';

import SwitchTheme from 'components/common/SwitchTheme';
import Parser, {
  ALLOWED_TAGS_BLOCK as allowedTags,
} from 'components/common/Parser';

import {
  FooterStyled,
  FooterContentStyled,
  FooterContentLargeStyled,
  FooterContentMediumStyled,
  FooterContentMediumContentStyled,
  FooterLinkStyled,
  FooterHeadlineStyled,
  FooterCraftMenuMeta,
  FooterContactEmailLinkStyled,
  FooterParagraphStyled,
  FooterContactHeadlineStyled,
  FooterContactStyled,
  FooterCraftComponentSocialsStyled,
  FooterContentColumnsStyled,
  FooterContentColumnInnerWrapStyled,
  FooterContactAdressStyled,
  FooterContactStreetStyled,
  FooterContactStreetNameStyled,
  FooterContactStreetNumberStyled,
  FooterContactLocationStyled,
  FooterContactPostcodeStyled,
  FooterContactCityStyled,
  FooterContactPhoneStyled,
  FooterContactPhoneTitleStyled,
  FooterContactPhoneLinkStyled,
  FooterContactEmailStyled,
  FooterContactEmailTitleStyled,
  FooterMenuMetaStyled,
  FooterMailchimpSignupStyled,
} from './Footer.styles';

/**
 * Component: Footer
 *
 * @param element
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function Footer({ className, data }) {
  const footerDeal = data?.footerDeal?.[0];
  const footerContact = data?.footerContact?.[0];
  const footerNewsletter = data?.footerNewsletter?.[0];

  return (
    <FooterStyled className={className} id="contact">
      <SwitchTheme name="default">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FooterContentStyled paddedLeft>
            <FooterContentLargeStyled>
              <FooterContentColumnsStyled>
                <FooterContentColumnInnerWrapStyled>
                  {footerDeal?.footerDealHeadline?.content && (
                    <FooterHeadlineStyled element="h3">
                      <Parser html={footerDeal?.footerDealHeadline?.content} />
                    </FooterHeadlineStyled>
                  )}
                  {footerDeal?.footerDealText?.content && (
                    <FooterParagraphStyled>
                      <Parser
                        html={footerDeal?.footerDealText?.content}
                        allowedTags={allowedTags}
                      />
                    </FooterParagraphStyled>
                  )}
                  {footerDeal?.footerDealEmailVisual &&
                    footerDeal?.footerDealEmail && (
                      <FooterLinkStyled
                        to={`mailto:${footerDeal?.footerDealEmail}`}
                      >
                        {footerDeal?.footerDealEmailVisual}
                      </FooterLinkStyled>
                    )}

                  {footerDeal?.footerDealLink?.url && (
                    <FooterLinkStyled
                      target={footerDeal?.footerDealLink?.target || null}
                      to={footerDeal?.footerDealLink?.url}
                    >
                      {footerDeal?.footerDealLink?.text}
                    </FooterLinkStyled>
                  )}
                </FooterContentColumnInnerWrapStyled>

                {footerNewsletter?.newsletterSubmitText &&
                  footerNewsletter?.newsletterValue && (
                    <FooterMailchimpSignupStyled
                      footerNewsletter={footerNewsletter}
                    />
                  )}
              </FooterContentColumnsStyled>
            </FooterContentLargeStyled>
            <FooterContentMediumStyled>
              <FooterContentMediumContentStyled>
                <FooterContentColumnsStyled>
                  <FooterContactStyled className="h-card">
                    {footerContact?.footerContactHeadline && (
                      <FooterContactHeadlineStyled
                        element="h6"
                        className="p-name"
                      >
                        <Parser html={footerContact?.footerContactHeadline} />
                      </FooterContactHeadlineStyled>
                    )}

                    <FooterContactAdressStyled className="h-adr">
                      {footerContact?.footerContactStreet &&
                        footerContact?.footerContactStreetNumber && (
                          <FooterContactStreetStyled className="p-street-address">
                            <FooterContactStreetNameStyled>
                              {footerContact?.footerContactStreet}
                            </FooterContactStreetNameStyled>
                            <FooterContactStreetNumberStyled>
                              {footerContact?.footerContactStreetNumber}
                            </FooterContactStreetNumberStyled>
                          </FooterContactStreetStyled>
                        )}

                      {footerContact?.footerContactPostcode &&
                        footerContact?.footerContactCity && (
                          <FooterContactLocationStyled>
                            <FooterContactPostcodeStyled className="p-postal-code">
                              {footerContact?.footerContactPostcode}
                            </FooterContactPostcodeStyled>
                            <FooterContactCityStyled className="p-locality">
                              {footerContact?.footerContactCity}
                            </FooterContactCityStyled>
                          </FooterContactLocationStyled>
                        )}
                    </FooterContactAdressStyled>

                    {footerContact?.footerContactPhoneTitle &&
                      footerContact?.footerContactPhone &&
                      footerContact?.footerContactPhoneVisual && (
                        <FooterContactPhoneStyled>
                          <FooterContactPhoneTitleStyled>
                            {footerContact?.footerContactPhoneTitle}
                          </FooterContactPhoneTitleStyled>
                          <FooterContactPhoneLinkStyled
                            className="p-tel"
                            to={`tel:${footerContact?.footerContactPhone}`}
                          >
                            {footerContact?.footerContactPhoneVisual}
                          </FooterContactPhoneLinkStyled>
                        </FooterContactPhoneStyled>
                      )}
                    {footerContact?.footerContactEmailTitle &&
                      footerContact?.footerContactEmail &&
                      footerContact?.footerContactEmailVisual && (
                        <FooterContactEmailStyled>
                          <FooterContactEmailTitleStyled>
                            {footerContact?.footerContactEmailTitle}
                          </FooterContactEmailTitleStyled>
                          <FooterContactEmailLinkStyled
                            className="u-email"
                            to={`mailto:${footerContact?.footerContactEmail}`}
                          >
                            {footerContact?.footerContactEmailVisual}
                          </FooterContactEmailLinkStyled>
                        </FooterContactEmailStyled>
                      )}
                  </FooterContactStyled>
                </FooterContentColumnsStyled>
              </FooterContentMediumContentStyled>
            </FooterContentMediumStyled>
          </FooterContentStyled>

          <FooterContentStyled paddedLeft>
            <FooterContentLargeStyled>
              <FooterContentColumnsStyled>
                <FooterMenuMetaStyled>
                  <FooterCraftMenuMeta />
                </FooterMenuMetaStyled>
              </FooterContentColumnsStyled>
            </FooterContentLargeStyled>
            <FooterContentMediumStyled>
              <FooterContentMediumContentStyled>
                <FooterContentColumnsStyled>
                  <FooterContentColumnInnerWrapStyled>
                    {footerDeal?.footerDealSocials && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 16,
                          marginTop: '-6px',
                        }}
                      >
                        <span
                          style={{
                            fontSize: 9,
                            textTransform: 'uppercase',
                          }}
                        >
                          connect with us on
                        </span>
                        <FooterCraftComponentSocialsStyled size="small" />
                      </div>
                    )}
                  </FooterContentColumnInnerWrapStyled>
                </FooterContentColumnsStyled>
              </FooterContentMediumContentStyled>
            </FooterContentMediumStyled>
          </FooterContentStyled>
        </div>
      </SwitchTheme>
    </FooterStyled>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: null,
};
