import styled from '@emotion/styled';

import Link from 'components/common/Link';

export const TextLinkStyled = styled(Link)(props => ({
  cursor: 'pointer',
  textDecoration: 'none',

  // Themed
  color: props.theme.textLinkColor,

  // State: hover
  '&:hover': {
    textDecoration: 'underline',
    color: props.theme.textLinkColorHover,
  },
}));
