import { useEffect, useRef } from 'react';

export const useIntersectionObserver = ({
  root,
  target,
  onIntersect,
  threshold = 0,
  rootMargin = '0px',
}) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = onIntersect;
  });

  useEffect(() => {
    function onIntersect(args) {
      savedCallback.current(args);
    }

    if (target && target.current) {
      const observer = new IntersectionObserver(onIntersect, {
        root: root ? root.current : null,
        rootMargin,
        threshold,
      });

      observer.observe(target.current);

      // Let's clean up after ourselves.
      return () => {
        target.current && observer.unobserve(target.current);
      };
    }
  }, [root, target, threshold, rootMargin]);
};
