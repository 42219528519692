import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import Icon from 'components/common/Icon';

export const LogoStyled = styled(Icon)(
  {
    pointerEvents: 'initial', // reset important for hero section and its pointer events, make sure to activate it again in sub components ~DH
    svg: {
      fill: 'currentColor',
      height: 42,
      width: 110,

      [media('md')]: {
        height: 60,
        width: 160,
      },
    },
  },
  props =>
    props.small && {
      width: 110,
    },
);
