import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import { fonts, sizes } from 'config/variables';
import { toPx } from 'utils/styles';

import Icon from 'components/common/Icon';

export const ButtonStyled = styled('button')(props => ({
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: 100,
  fontWeight: 600,
  minWidth: 250,
  maxWidth: '100%',
  fontSize: fonts.sizes.smaller,
  textDecoration: 'none',
  cursor: 'pointer',
  padding: toPx(sizes.spacing),
  margin: toPx(sizes.spacing),
  marginLeft: 0,
  transition: 'background-color .4s, border-color .4s',

  // Themed
  color: props.theme.buttonColor,
  backgroundColor: props.theme.buttonBackgroundColor,
  border: `1px solid ${props.theme.buttonBorderColor}`,

  [media('sm')]: {
    marginTop: toPx(sizes.spacing * 3),
  },

  // State: hover
  '&:hover': {
    color: props.theme.buttonColorHover,
    backgroundColor: props.theme.buttonBackgroundColorHover,
    borderColor: props.theme.buttonBorderColorHover,
  },
}));

export const TextStyled = styled('span')({
  flex: '1',
  textAlign: 'center',
  lineHeight: 1,
  padding: `${toPx(sizes.spacing)} ${toPx(sizes.spacing + 42)} ${toPx(
    sizes.spacing,
  )} ${toPx(sizes.spacing + 6)}`,
});

export const IconWrapperStyled = styled('span')(props => ({
  flex: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  borderRadius: 100,
  justifyContent: 'center',
  fontSize: '2.6em',
  width: 36,
  height: 36,
  marginRight: 0,

  // Themed
  color: props.theme.buttonIconColor,
  backgroundColor: props.theme.buttonIconBackgroundColor,
  border: `1px solid ${props.theme.buttonIconBorderColor}`,

  // State: parent hover
  [`${ButtonStyled}:hover &`]: {
    color: props.theme.buttonIconColorHover,
    backgroundColor: props.theme.buttonIconBackgroundColorHover,
    borderColor: props.theme.buttonIconBorderColorHover,
    transition: 'background-color .4s, border-color .4s',
  },
}));

export const IconStyled = styled(Icon)({
  // This is here but empty, so we can inherit it from other places.
  width: '0.6em',
  height: '0.6em',
  svg: {
    height: '0.6em',
  },
});
