import React, { createContext, useState } from 'react';

export const LayoutContext = createContext({
  activeSegmentId: null,
  setActiveSegmentId: () => {},
  themeName: null,
  setThemeName: () => {},
});

export function LayoutContextProvider({ children }) {
  const [activeSegmentId, setActiveSegmentId] = useState();
  const [themeName, setThemeName] = useState();

  return (
    <LayoutContext.Provider
      value={{ activeSegmentId, setActiveSegmentId, themeName, setThemeName }}
    >
      {children}
    </LayoutContext.Provider>
  );
}
