import React from 'react';
import PropTypes from 'prop-types';

import { WrapperStyled } from './Wrapper.styles';

const justifyContentOptions = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  spaced: 'space-between',
};

const alignItemsOptions = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
};

/**
 * Component: Wrapper
 *
 * This component is here to keep
 * the content in check.
 *
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function Wrapper({
  className,
  paddedLeft,
  paddedRight,
  smallPaddedLeft,
  smallPaddedRight,
  flexDirection,
  justifyContent,
  alignItems,
  children,
  parentId,
}) {
  return (
    <WrapperStyled
      id={parentId && `${parentId}__wrapper`}
      className={className}
      paddedLeft={paddedLeft}
      paddedRight={paddedRight}
      smallPaddedLeft={smallPaddedLeft}
      smallPaddedRight={smallPaddedRight}
      flexDirection={flexDirection}
      alignItems={
        alignItems in alignItemsOptions ? alignItemsOptions[alignItems] : null
      }
      justifyContent={
        justifyContent in justifyContentOptions
          ? justifyContentOptions[justifyContent]
          : null
      }
    >
      {children}
    </WrapperStyled>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  justifyContent: PropTypes.oneOf(['start', 'end', 'center', 'spaced']),
  alignItems: PropTypes.oneOf(['start', 'end', 'center']),
  flexDirection: PropTypes.oneOf(['row', 'column']),
  paddedLeft: PropTypes.bool,
  paddedRight: PropTypes.bool,
};

Wrapper.defaultProps = {
  flexDirection: null,
  justifyContent: 'start',
  alignItems: null,
  paddedLeft: false,
  paddedRight: false,
};
