import styled from '@emotion/styled';

import media from 'utils/mediaqueries';
import { fonts } from 'config/variables';

export const TextWrapperStyled = styled('div')(
  props =>
    props.appearance === 'intro' && {
      lineHeight: 1.6,
      fontSize: fonts.sizes.large,
    },
  props =>
    props.appearance === 'smallFaded' && {
      fontSize: fonts.sizes.small,
      opacity: 0.8,
    },
  props =>
    props.paddedRight && {
      paddingRight: '10%',

      [media('sm')]: {
        paddingRight: '30%',
      },

      [media('lg')]: {
        paddingRight: '40%',
      },
    },
);
