import { useState, useEffect } from 'react';
import throttle from 'lodash/throttle';

/**
 * Hook:
 * Scroll position
 *
 * ----------------------------
 * Usage:
 *
 * function App() {
 *   const scrollPosition = useScrollPosition();
 *
 *   return (
 *     <div>
 *       {scrollPosition.scrollPosition}px
 *     </div>
 *   );
 * }
 * ----------------------------
 */
export const useScrollPosition = () => {
  const isClient = typeof document === 'object';

  function getPosition() {
    return isClient
      ? document.documentElement.scrollTop || document.body.scrollTop
      : undefined;
  }

  const [scrollPosition, setScrollPosition] = useState(getPosition);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollPosition(getPosition());
    }, 100);

    if (!isClient) {
      return false;
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }); // Empty array ensures that effect is only run on mount and unmount

  return scrollPosition;
};
