import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Footer from 'components/common/Footer';

export default props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        craft {
          globals {
            globalFooter {
              footerNewsletter {
                newsletterHeadline
                newsletterPlaceholder
                newsletterValue
                newsletterSubmitText
                newsletterDescription {
                  content
                }
              }
              footerDeal {
                id
                footerDealHeadline {
                  content
                }
                footerDealText {
                  content
                }
                footerDealEmail
                footerDealEmailVisual
                footerDealSocials
                footerDealLink {
                  text
                  target
                  url
                }
              }
              footerContact {
                id
                footerContactHeadline
                footerContactStreet
                footerContactStreetNumber
                footerContactPostcode
                footerContactCity
                footerContactPhoneTitle
                footerContactPhone
                footerContactPhoneVisual
                footerContactEmailTitle
                footerContactEmail
                footerContactEmailVisual
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Footer
        data={data?.craft?.globals?.globalFooter || undefined}
        {...props}
      />
    )}
  />
);
