import React from 'react';
import PropTypes from 'prop-types';

import {
  SocialsStyled,
  SocialsItemStyled,
  SocialsItemIconStyled,
} from './Socials.styles';

export default function Socials({ className, items, size }) {
  return items ? (
    <SocialsStyled className={className}>
      {items.map(item => (
        <SocialsItemStyled
          key={item?.id}
          to={item?.socialItemLink?.url}
          target={item?.socialItemLink?.target}
          type={item?.socialItemType}
          size={size}
        >
          <SocialsItemIconStyled name={item?.socialItemType} />
        </SocialsItemStyled>
      ))}
    </SocialsStyled>
  ) : null;
}

Socials.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
};

Socials.defaultProps = {
  className: null,
  items: null,
};
