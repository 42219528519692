import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useIntersectionObserver } from 'utils/hooks/useIntersectionObserver';

import {
  HeadlineH1Styled,
  HeadlineH2Styled,
  HeadlineH3Styled,
  HeadlineH4Styled,
  HeadlineH5Styled,
  HeadlineH6Styled,
} from './Headline.styles';

/**
 * Component: Headline
 *
 * @param element
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function Headline({
  element,
  className,
  appearance,
  align,
  children,
}) {
  const [isVisible, setIsVisible] = useState(false);

  function handleIntersect([{ isIntersecting }]) {
    setIsVisible(isIntersecting);
  }

  const scrollCheckTarget = useRef(null);
  useIntersectionObserver({
    target: scrollCheckTarget,
    onIntersect: handleIntersect,
  });

  const elementProps = {
    className,
    appearance,
    align,
  };

  switch (element) {
    case 'h1':
      return (
        <HeadlineH1Styled
          {...elementProps}
          ref={scrollCheckTarget}
          isVisible={isVisible}
        >
          {children}
        </HeadlineH1Styled>
      );
    case 'h2':
      return (
        <HeadlineH2Styled
          {...elementProps}
          ref={scrollCheckTarget}
          isVisible={isVisible}
        >
          {children}
        </HeadlineH2Styled>
      );
    case 'h3':
      return (
        <HeadlineH3Styled
          {...elementProps}
          ref={scrollCheckTarget}
          isVisible={isVisible}
        >
          {children}
        </HeadlineH3Styled>
      );
    case 'h4':
      return (
        <HeadlineH4Styled
          {...elementProps}
          ref={scrollCheckTarget}
          isVisible={isVisible}
        >
          {children}
        </HeadlineH4Styled>
      );
    case 'h5':
      return (
        <HeadlineH5Styled
          {...elementProps}
          ref={scrollCheckTarget}
          isVisible={isVisible}
        >
          {children}
        </HeadlineH5Styled>
      );
    case 'h6':
      return (
        <HeadlineH6Styled
          {...elementProps}
          ref={scrollCheckTarget}
          isVisible={isVisible}
        >
          {children}
        </HeadlineH6Styled>
      );
    default:
      console.warn(`Invalid element ${element} for Headline.`);
      return null;
  }
}

Headline.propTypes = {
  element: PropTypes.string,
  appearance: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right']),
  children: PropTypes.node.isRequired,
};

Headline.defaultProps = {
  element: 'h2',
  appearance: null,
  align: 'left',
};
