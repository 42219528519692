import React from 'react';
import PropTypes from 'prop-types';

import { TextWrapperStyled } from './TextWrapper.styles';

/**
 * Component: TextWrapper
 *
 * Wraps multiple paragraphs into a wrapping div.
 *
 * @version 1.0.0
 *
 * @param className
 * @param children
 *
 * @returns {null|object}
 */
export default function TextWrapper({
  className,
  children,
  appearance,
  paddedRight,
}) {
  return (
    <TextWrapperStyled
      className={className}
      appearance={appearance}
      paddedRight={paddedRight}
    >
      {children}
    </TextWrapperStyled>
  );
}

TextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  appearance: PropTypes.string,
  paddedRight: PropTypes.bool,
};

TextWrapper.defaultProps = {
  appearance: null,
  paddedRight: false,
};
