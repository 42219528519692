import styled from '@emotion/styled';

export const IconStyled = styled('span')(
  {
    display: 'inline-flex',
    flex: 'none',
  },
  props =>
    props.disabled && {
      opacity: 0.5,
      pointerEvents: 'none',
    },
);

export const SvgStyled = styled('svg')({
  width: '1em',
  height: '1em',
  fill: 'currentColor',
});
